import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Search from "../../components/Form/Search.js";
import "../../assets/styles/pages/Usuarios.scss";
import "pure-css-loader/src/loader-default.sass";
import business from "../../services/business.js";
import layout from "../../lib/layout.js";
import config from "../../config/config.js";
import Layout from "../../components/Layout.js";
import MenuDots from "../../components/MenuDots.js";
import ModalProcedimentoAdmin from "../../components/Modais/ModalProcedimentoAdmin.js";
import tools from "../../lib/tools.js";

export default function Procedimentos() {
    document.title = "säästo";
    layout.openMenu("/administracao/Procedimentos", true);
    const [pager, setPager] = useState({
        page: 1,
        total_pages: 0,
        total_rows: 0,
    });

    const [listagem, setListagem] = useState([]);
    const [showModalProcedimentos, setShowModalProcedimentos] = useState(false);
    const [processo, setProcesso] = useState({});
    const [isMobile, setIsMobile] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSaveSuccess = async () => {
        // Atualiza a lista de procedimentos após o salvamento
        await getSearch();
    };

    const childToParent = async () => {
        setShowModalProcedimentos(true);
    };

    const openModal = async (event = {}, row = {}) => {
        event?.preventDefault();
        if (row?.id) setProcesso(row);
        setShowModalProcedimentos(true);
    };

    const hideModalProcedimentos = async (event) => {
        event?.preventDefault();
        setShowModalProcedimentos(false);
        const body = document.body;
        body.classList.remove("overflow-hidden");
    };

    const menuItens = [
        {
            label: "Editar",
            icon: "icon-edit blue i-25",
            className: "menu-icon",
            onClick: openModal,
            disabled: !config.temPermissao("admin:usuarios:editar"),
        }
    ];

    const handlePageClick = (e) => {
        getSearch(e.selected + 1);
    };

    const getSearch = async (page = null, value = "", loading = true) => {
        setLoading(loading);
        let procedimentos = await business.getAllProcedimentosAdmin(
            page === null ? pager.page : page,
            20,
            value,
            [6]
        );
        setListagem(procedimentos?.response || []);
        if (
            procedimentos?.response &&
            pager.page &&
            procedimentos?.pager?.paginas > 0 &&
            procedimentos?.pager?.paginas < pager.page
        ) {
            pager.page = 1;
            return getSearch();
        }
        setPager({
            page: procedimentos?.pager?.pagina,
            total_pages: procedimentos?.pager?.paginas,
            total_rows: procedimentos?.pager?.total,
        });
        setLoading(false);
        setSearchLoading(false);
    };

    const handleWindowSizeChange = (mobileSize) => {
        let checkIsMobile = window.innerWidth < mobileSize;
        setIsMobile(checkIsMobile);
    };

    useEffect(() => {
        window.addEventListener("resize", () => {
            return handleWindowSizeChange(1180);
        });
    }, []);

    useEffect(() => {
        (async () => {
            await getSearch();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout className="view-listagem">
            {showModalProcedimentos && (
                <ModalProcedimentoAdmin
                    show={showModalProcedimentos}
                    hideModal={hideModalProcedimentos}
                    dto={processo}
                    onSaveSuccess={handleSaveSuccess}
                />
            )}
            <p className="page-title">
                Administração / <span className="blue-text">Procedimentos</span>
            </p>
            <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
                Procedimentos
            </h2>
            <div className="flex jc-sb ai-fc mb-60">
                <div className="container-search-filter flex ai-fc jc-sb gap-25">
                    <Search
                        onChange={(value) => {
                            setSearchLoading(true);
                            getSearch(1, value, false);
                        }}
                        searchOnTyping={isMobile}
                        loading={searchLoading}
                    />
                </div>

                <div className="flex ai-fc gap-30 desktop">
                    {config.temPermissao("admin:usuarios:cadastrar") && (
                        <button className="btn btn-primary f700-16" onClick={childToParent}>
                            Cadastrar
                        </button>
                    )}
                </div>
            </div>

            {loading ? (
                <div className="loader loader-default is-active" />
            ) : (
                <div>
                    <div className="table-mobile">
                        {listagem.map((p, i) => {
                            return (
                                <div className="item pointer" key={`mobile-${i}`}>
                                    <div>
                                        <div className="fn-16 linha flex jc-sb">
                                            Código
                                            <span>{p.codigo}</span>
                                        </div>
                                        <div className="fn-16 linha flex jc-sb">
                                            Descrição
                                            <span>{p.desc}</span>
                                        </div>
                                        <div className="fn-16 linha flex jc-sb">
                                            Valor
                                            <span>{tools.formatCurrency(p.valor_unitario)}</span>
                                        </div>
                                        <div className="fn-16 linha flex jc-sb">
                                            Cobertura ROL
                                            <span>{parseInt(p.cobertura_rol) === 1 ? "Sim" : "Não"}</span>
                                        </div>
                                        <div className="fn-16 linha flex jc-sb">
                                            DUT
                                            <span>{parseInt(p.dut) === 1 ? "Sim" : "Não"}</span>
                                        </div>
                                        <div className="fn-16 linha flex jc-sb">
                                            Status
                                            <span>{parseInt(p.status) === 1 ? <strong>Ativo</strong> : "Destivado"}</span>
                                        </div>
                                    </div>
                                    <div className="w-100 flex jc-fc">
                                        <MenuDots
                                            row={p}
                                            menuItens={menuItens}
                                            menuPositionTop={true}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        {(!listagem || !listagem?.length) && (
                            <div className="item pointer" key={`mobile-0`}>
                                Nenhum resultado encontrado.
                            </div>
                        )}
                    </div>
                    <table className="w-100 table-desktop">
                        <thead className="f300-16">
                            <tr>
                                <th className="bold">Código</th>
                                <th className="text-left bold">Descrição</th>
                                <th className="text-left bold">Valor</th>
                                <th className="text-center bold">Cobertura ROL</th>
                                <th className="text-center bold">DUT</th>
                                <th className="text-center bold">Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {listagem.map((p, i) => {
                                return (
                                    <tr key={`desktop-${i}`}>
                                        <td className="fn-16"><strong>{p.codigo}</strong></td>
                                        <td className="fn-16">{p.desc}</td>
                                        <td className="fn-16">{tools.formatCurrency(p.valor_unitario)}</td>
                                        <td className="fn-16 text-center">{parseInt(p.cobertura_rol) === 1 ? "Sim" : "Não"}</td>
                                        <td className="fn-16 text-center">{parseInt(p.dut) === 1 ? "Sim" : "Não"}</td>
                                        <td className="fn-16 text-center">{parseInt(p.status) === 1 ? "Ativo" : "Desativado"}</td>
                                        <td className="text-center" style={{ width: "35px" }}>
                                            <MenuDots
                                                row={p}
                                                menuItens={menuItens}
                                                menuPositionTop={listagem.length - i <= 2}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                            {(!listagem || !listagem?.length) && (
                                <tr>
                                    <td colSpan={7}>Nenhum resultado encontrado.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="component pagination">
                        <div className="pagination-container mt-30">
                            {pager.total_pages < 2 ? (
                                ""
                            ) : (
                                <ReactPaginate
                                    previousLabel="«"
                                    nextLabel="»"
                                    breakLabel="..."
                                    breakClassName="break-me"
                                    page={pager.page - 1 ?? 0}
                                    forcePage={pager.page - 1 ?? 0}
                                    pageCount={pager.total_pages}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName="pagination"
                                    subContainerClassName="pages pagination"
                                    activeClassName="active"
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
}
