import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout.js";
import '../../assets/styles/pages/Usuarios.scss'
import 'pure-css-loader/src/loader-default.sass';
import business from "../../services/business.js";
import layout from '../../lib/layout.js';
import config from '../../config/config.js';
import tools from '../../lib/tools.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Loading from '../../components/Loading.js';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import InputForm from '../../components/Form/InputForm.js';


export default function TotalEconomizado() {

    let user = config.user;
    document.title = 'säästo';

    layout.openMenu('/usuarios', true);
    const [pager, setPager] = useState({
        page: 1,
        total_pages: 0,
        total_rows: 0,
    });

    const form = useState({});
    const [options, setOptions] = useState({})
    const [loaderPdf, setLoaderPdf] = useState(false);
    const [loaderCsv, setLoaderCsv] = useState(false);
    const [processos, setProcessos] = useState([]);
    const [filtros] = useState({
        id_operadora: (!config.isAdmin() && !user.tipo === '7') ? user?.id_operadora : '',
        desc_operadora: (!config.isAdmin() && !user.tipo === '7') ? user?.desc_operadora : ''
    });

    const handlePageClick = (e) => {
        getSearch(e.selected + 1);
    };

    const dataExport = async () => {
        return await business.exportRelatorio({
            id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : (filtros?.id_operadora ? filtros.id_operadora : (filtrosProcessos?.id_operadora ? filtrosProcessos.id_operadora : '')),
            id_desempatador: (user.tipo === '6') ? user.id : (filtrosProcessos.id_desempatador || ''),
            id_processo_etapa: filtrosProcessos?.id_processo_etapa || '',
            id_processo_tipo: filtrosProcessos.typeProcess || '',
            especialidade: filtrosProcessos.especialidade || '',
            area_atuacao: filtrosProcessos.areaAtuacao || '',
            id_processo_etapa_status: filtrosProcessos?.id_processo_etapa_status || '',
            id_assistente: filtrosProcessos?.id_assistente || '',
            data_conclusao_inicio: filtrosProcessos.inicio_processo,
            data_conclusao_fim: filtrosProcessos.data_conclusao,
        });
    }

    const getSearch = async (page = null, loading = true) => {
        setLoading(loading);

        let processos = await business.getAllRelatorios((page === null) ? pager.page : page, 10, {
            id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : (filtros?.id_operadora ? filtros.id_operadora : (filtrosProcessos?.id_operadora ? filtrosProcessos.id_operadora : '')),
            id_desempatador: (user.tipo === '6') ? user.id : (filtrosProcessos.id_desempatador || ''),
            id_processo_etapa: filtrosProcessos?.id_processo_etapa || '',
            id_processo_tipo: filtrosProcessos.typeProcess || '',
            especialidade: filtrosProcessos.especialidade || '',
            area_atuacao: filtrosProcessos.areaAtuacao || '',
            id_processo_etapa_status: filtrosProcessos?.id_processo_etapa_status || '',
            id_assistente: filtrosProcessos?.id_assistente || '',
            data_conclusao_inicio: filtrosProcessos.inicio_processo,
            data_conclusao_fim: filtrosProcessos.data_conclusao,
        });

        setProcessos(processos?.response || [])
        if (processos?.response && pager.page && processos?.pager?.paginas < pager.page) {
            pager.page = 1;
            return getSearch();
        }

        pager.page = processos?.pager?.pagina;
        setPager({
            page: processos?.pager?.pagina,
            total_pages: processos?.pager?.paginas,
            total_rows: processos?.pager?.total
        });
        setLoading(false);
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await getSearch();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const exportToPdf = async () => {
        setLoaderPdf(true);
        let data = (await dataExport())?.response || [];

        // Filtra as colunas visíveis
        const visibleColumnsIds = visibleColumns.filter(col => col.visible).map(col => col.id);
        const filteredData = data.map(elemento => {
            const filteredElement = {};
            visibleColumnsIds.forEach(id => {
                filteredElement[id] = elemento[id];
            });
            return filteredElement;
        });

        const valoresBodyPdf = filteredData.map(elemento => {
            const valores = Object.values(elemento);

            if (valores[1]) {
                valores[1] = tools.formatDate(valores[1]);
            }

            if (valores[2]) {
                valores[2] = valores[2].length ? tools.formatDate(valores[2]) : '-';
            }

            return valores;
        });

        const doc = new jsPDF({ orientation: 'l' });
        autoTable(doc, { html: '#my-table' });
        const header = visibleColumns.filter(col => col.visible).map(col => col.label); // Cabeçalho com colunas visíveis
        const tableStartY = 10;
        autoTable(doc, {
            head: [header],
            body: valoresBodyPdf,
            startY: tableStartY,
            styles: { cellPadding: 2, fontSize: 6 },
            columnStyles: { 0: { halign: 'center' } },
            margin: { top: 10 },
        });
        doc.save('total-economizado.pdf');
        setLoaderPdf(false);
    };

    const exportToCsv = async () => {
        setLoaderCsv(true);
        let filename = 'total-economizado.csv';
        let data = (await dataExport())?.response || [];

        // Filtra as colunas visíveis
        const visibleColumnsIds = visibleColumns.filter(col => col.visible).map(col => col.id);
        const filteredData = data.map(elemento => {
            const filteredElement = {};
            visibleColumnsIds.forEach(id => {
                filteredElement[id] = elemento[id];
            });
            return filteredElement;
        });

        const options = { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 };
        const numberFormatter = new Intl.NumberFormat('pt-BR', options);

        const processValue = (value) => {
            if (value === null) {
                return '';
            } else if (value instanceof Date) {
                return value.toLocaleString();
            } else if (typeof value === 'number') {
                return numberFormatter.format(value);
            } else if (typeof value === 'string') {
                if (value.startsWith('+')) {
                    return `'${value}`;
                } else {
                    return `"${value.replace(/"/g, '""')}"`;
                }
            }
            return value;
        };

        const csvRows = [visibleColumns.filter(col => col.visible).map(col => col.label)]; // Cabeçalho com colunas visíveis
        filteredData.forEach((objeto) => {
            csvRows.push(Object.values(objeto).map(processValue));
        });

        const csvFile = csvRows.map((row) => row.join(';')).join('\n');
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setLoaderCsv(false);
    };

    const [visibleColumns, setVisibleColumns] = useState([
        { id: 'id_protocolo', label: 'Protocolo', visible: true },
        { id: 'inicio_processo', label: 'Data de Início', visible: true },
        { id: 'data_conclusao', label: 'Data de Conclusão', visible: true },
        { id: 'desc_processo_tipo', label: 'Tipo de Processo', visible: true },
        { id: 'desc_especialidade', label: 'Especialidade', visible: true },
        { id: 'desc_area_atuacao', label: 'Área de Atuação', visible: true },
        { id: 'desc_operadora', label: 'Operadora', visible: true },
        { id: 'plano', label: 'Plano', visible: true },
        { id: 'nome_beneficiario', label: 'Beneficiário', visible: true },
        { id: 'cpf_beneficiario', label: 'CPF', visible: true },
        { id: 'guia', label: 'Nº Guia', visible: false },
        { id: 'profissional_assistente', label: 'Profissional Assistente', visible: false },
        { id: 'profissional_assistente_crm', label: 'CRM/CRO', visible: false },
        { id: 'nome_desempatador', label: 'Desempatador', visible: false },
        { id: 'assistente_saasto', label: 'Assistente', visible: false },
        { id: 'enfermeiro_saasto', label: 'Enfermeira', visible: false },
        { id: 'desc_processo_etapa', label: 'Etapa', visible: false },
        { id: 'desc_processo_etapa_status', label: 'Status', visible: false },
        { id: 'processo_conclusao', label: 'Conclusão', visible: false },
        { id: 'total_procedimentos_inicial', label: 'Valor Inicial Procedimentos', visible: false },
        { id: 'total_materiais_inicial', label: 'Valor Inicial Materiais', visible: false },
        { id: 'total_inicial', label: 'Valor Inicial Total', visible: false },
        { id: 'total_procedimentos_final', label: 'Valor Final Procedimentos', visible: false },
        { id: 'total_materiais_final', label: 'Valor Final Materiais', visible: false },
        { id: 'total_final', label: 'Valor Final Total', visible: false },
        { id: 'total_economizado_procedimentos', label: 'Economizado em Procedimentos', visible: false },
        { id: 'total_economizado_materiais', label: 'Economizado em Materiais', visible: false },
        { id: 'total_economizado', label: 'Total Economizado', visible: false },
    ]);

    const [selectedColumns, setSelectedColumns] = useState(visibleColumns.filter(col => col.visible).map(col => col.id));

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedColumns(value);
        setVisibleColumns(prevColumns =>
            prevColumns.map(column =>
                ({ ...column, visible: value.includes(column.id) })
            )
        );
    };

    const [filtrosProcessos, setFiltrosProcessos] = useState({
        id_operadora: '',
        id_processo_tipo: '',
        inicio_processo: '',
        especialidade: '',
        areaAtuacao: '',
        id_processo_etapa: '',
        id_processo_etapa_status: '',
        id_desempatador: '',
        id_assistente: '',
        data_conclusao: '',
    });

    const setModel = async (name, value) => {
        await setFiltrosProcessos(prev => ({ ...prev, [name]: value }));
    };

    const searchOperadora = async (e) => {
        e.preventDefault();
        return await business.getOperadora(e.target.value);
    }

    const searchEspecialidades = async (e) => {
        return await business.getEspecialidades(e.target.value, 5, 1, form.id_area_atuacao);
    }

    const searchAreaAtuacao = async (e) => {
        return await business.getAreaAtuacao(e.target.value, 12, 1, form.id_especialidade);
    }

    const searchDesempatadores = async (e) => {
        e.preventDefault();
        return await business.getDesempatadores(e.target.value);
    }

    const searchAssistentes = async (e) => {
        e.preventDefault();
        return await business.getProfissional(e.target.value);
    }

    const typeProcess = [
        {
            id: 1,
            nome: "Segunda Opinião"
        },
        {
            id: 2,
            nome: "Consenso Pré"
        },
        {
            id: 3,
            nome: "Junta Médica ou Odontológica"
        }
    ];

    const getCombosOPS = async () => {

        let etapas = [
            { id: 0, nome: 'Todas as etapas' },
            { id: 12, nome: 'Rascunho' },
            { id: 1, nome: 'Espera' },
            { id: 2, nome: 'Análise' },
            { id: 6, nome: 'Análise técnica' },
            { id: 8, nome: 'Ciência de abertura' },
            { id: 7, nome: 'Consenso Pós' },
            { id: 9, nome: 'Definir desempatador' },
            { id: 3, nome: 'Análise desempatador' },
            { id: 10, nome: 'Validação' },
            { id: 11, nome: 'Ciência de conclusão' },
            { id: 4, nome: 'Finalizado' },
            { id: 5, nome: 'Cancelado' }
        ];
        let status = [
            { id: 0, nome: 'Todos os status' },
            { id: 24, nome: 'Não enviado' },
            { id: 4, nome: 'Documentação pendente' },
            { id: 3, nome: 'Informações pendentes' },
            { id: 7, nome: 'Finalizado' },
            { id: 12, nome: 'Finalizado por Consenso' },
            { id: 8, nome: 'Cancelado' },
        ];
        setOptions({ ...{ etapas, status }, ...options })
    }

    const getCombos = async () => {

        let etapas = [
            { id: 0, nome: 'Todas as etapas' },
            { id: 2, nome: 'Análise' },
            { id: 3, nome: 'Análise desempatador/especialista' },
            { id: 6, nome: 'Análise técnica' },
            { id: 316, nome: 'Auditoria' },
            { id: 317, nome: 'Auditoria (Pós)' },
            { id: 5, nome: 'Cancelado' },
            { id: 8, nome: 'Ciência de abertura' },
            { id: 11, nome: 'Ciência de conclusão' },
            { id: 318, nome: 'Ciência de conclusão' },
            { id: 7, nome: 'Consenso Pós' },
            { id: 13, nome: 'Consenso Pós (Enfermeira)' },
            { id: 14, nome: 'Consenso Pré' },
            { id: 9, nome: 'Definir desempatador/especialista' },
            { id: 1, nome: 'Espera' },
            { id: 4, nome: 'Finalizado' },
            { id: 15, nome: 'Reabertura' },
            { id: 12, nome: 'Rascunho' },
            { id: 10, nome: 'Validação' }
        ];
        let status = [
            { id: 0, nome: 'Todos os status' },
            { id: 11, nome: 'Acolhimento' },
            { id: 43, nome: 'Acolhimento' },
            { id: 44, nome: 'Aguardando aceites' },
            { id: 27, nome: 'Aguardando ajuste do desempatador' },
            { id: 25, nome: 'Aguardando assistente' },
            { id: 52, nome: 'Aguardando auditor' },
            { id: 31701, nome: 'Aguardando auditor' },
            { id: 14, nome: 'Aguardando ciência dos envolvidos' },
            { id: 22, nome: 'Aguardando desempatado/especialista' },
            { id: 23, nome: 'Aguardando enfermeira' },
            { id: 23, nome: 'Aguardando interação da enfermeira' },
            { id: 1, nome: 'Aguardando início' },
            { id: 15, nome: 'Aguardando PA' },
            { id: 18, nome: 'Analisando consenso pós' },
            { id: 2, nome: 'Analisando informações' },
            { id: 51, nome: 'Auditoria Concluída' },
            { id: 31703, nome: 'Auditoria Concluída' },
            { id: 46, nome: 'Cancelado pós parecer' },
            { id: 5, nome: 'Concluído' },
            { id: 10, nome: 'Concluído consenso' },
            { id: 31, nome: 'Concluído consenso pós' },
            { id: 39, nome: 'Concluído por consenso parcial' },
            { id: 21, nome: 'Devolvido para ajustes' },
            { id: 41, nome: 'Devolvido para säästö' },
            { id: 4, nome: 'Documentação pendente' },
            { id: 6, nome: 'Em análise pelo desempatador' },
            { id: 50, nome: 'Em auditoria' },
            { id: 31702, nome: 'Em auditoria' },
            { id: 9, nome: 'Em consenso' },
            { id: 26, nome: 'Encaminhado consenso para validação da OPS' },
            { id: 17, nome: 'Encaminhado para consenso' },
            { id: 28, nome: 'Encaminhado para enfermeira' },
            { id: 34, nome: 'Encaminhado para JMO' },
            { id: 49, nome: 'Encerrado o Prazo - Escolher Desempatador' },
            { id: 47, nome: 'Enviar convites' },
            { id: 10948, nome: 'Escolher desempatador' },
            { id: 42, nome: 'Finalizado por consenso pré' },
            { id: 12, nome: 'Finalizado por Consenso' },
            { id: 3, nome: 'Informações pendentes' },
            { id: 24, nome: 'Rascunho' },
            { id: 20, nome: 'Parecer em análise' },
            { id: 8, nome: 'Processo cancelado' },
            { id: 7, nome: 'Processo finalizado' },
            { id: 35, nome: 'Profissional assistente optou por abstenção' },
            { id: 36, nome: 'Profissional assistente optou por consenso' },
            { id: 37, nome: 'Profissional assistente selecionou desempatador' },
            { id: 45, nome: 'Reabertura de processo' },
            { id: 13, nome: 'Recusa de consenso' },
            { id: 33, nome: 'Sem contato com profissional assistente' },
            { id: 19, nome: 'Solicitar exames complementares' },
            { id: 30, nome: 'Voltar à etapa Análise Técnica' },
        ];
        setOptions({ ...{ etapas, status }, ...options })
    }

    const limparFiltros = () => {
        //limpar campos dos filtros e fazer nova busca
        /*     const elementos = [
              "id_processo_etapa",
              "id_processo_etapa_status",
              "data_criacao_inicio",
              "data_criacao_fim",
              "data_conclusao_inicio",
              "data_conclusao_fim"
            ];
            elementos.forEach(id => {
              const elemento = document.getElementById(id);
              if (elemento) {
                elemento.value = '';
              }
            });
            if ([1, 4, 5, 6, 7, 8].includes(parseInt(user.tipo))) {
              const id_operadora = document.getElementById("id_operadora");
              const id_usuario = document.getElementById("id_usuario");
              if (id_operadora) id_operadora.value = '';
              if (id_usuario) id_usuario.value = '';
            }
            if ([3].includes(parseInt(user.tipo))) {
              const id_usuario = document.getElementById("id_usuario");
              if (id_usuario) id_usuario.value = '';
            } */
        setFiltrosProcessos({});
        setFiltrosProcessos(false);
        window.location.reload();
        //getSearch(null, '', true);
    }

    useEffect(() => {
        (async () => {
            await getSearch();
            if (config.user.tipo === '2' || config.user.tipo === '3') {
                getCombosOPS()
            }
            else {
                getCombos()
            };
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    useEffect(() => {
        getSearch(1, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtrosProcessos]);

    const formatValue = (columnId, processo) => {
        switch (columnId) {
            case 'inicio_processo':
                return tools.formatDate(processo.inicio_processo);
            case 'data_conclusao':
                return processo.data_conclusao ? tools.formatDate(processo.data_conclusao) : '-';
            case 'total_procedimentos_inicial':
            case 'total_procedimentos_final':
            case 'total_materiais_inicial':
            case 'total_materiais_final':
                return processo[columnId] ? tools.formatCurrency(processo[columnId]) : '-';
            case 'total_economizado_procedimentos':
                return (
                    <span style={{ color: '#24BE34' }}>
                        {processo.total_economizado_procedimentos ? tools.formatCurrency(processo.total_economizado_procedimentos) : '-'}
                    </span>
                );
            case 'total_economizado_materiais':
                return (
                    <span style={{ color: '#24BE34' }}>
                        {processo.total_economizado_materiais ? tools.formatCurrency(processo.total_economizado_materiais) : '-'}
                    </span>
                );
            case 'total_inicial':
                return (
                    <span style={{ fontWeight: 'bold' }}>
                        {processo.total_inicial ? tools.formatCurrency(processo.total_inicial) : '-'}
                    </span>
                );
            case 'total_final':
                return (
                    <span style={{ fontWeight: 'bold' }}>
                        {processo.total_inicial ? tools.formatCurrency(processo.total_inicial) : '-'}
                    </span>
                );
            case 'total_economizado':
                return (
                    <span style={{ fontWeight: 'bold', color: '#24BE34' }}>
                        {processo.total_final ? tools.formatCurrency(processo.total_final) : '-'}
                    </span>
                );
            default:
                return processo[columnId] ? processo[columnId] : '-';
        }
    };

    return (
        <Layout className="view-listagem">
            <div className="flex jc-sb ai-fc gap-30 w-100 mb-40">
                <div>
                    <p className="page-title">Relatórios</p>
                    <h2 className="title black-text">
                        Gerencial
                    </h2>
                </div>
                <div className='flex gap-20'>
                    <button className="btn btn-primary f700-16" onClick={exportToPdf} disabled={loaderPdf || loaderCsv}>
                        {loaderPdf ? <Loading /> : <i className="icon icon-print i-25" />} <span className="pl-5">Imprimir</span>
                    </button>
                    <button className="btn btn-primary f700-16" onClick={exportToCsv} disabled={loaderCsv || loaderPdf}>
                        {loaderCsv ? <Loading /> : <i className="icon icon-document i-25" />}<span className="pl-5">Exportar</span>
                    </button>
                </div>
            </div>
            <div className="flex ai-fc jc-sb mb-30">
                <div className="flex ai-fc gap-25">
                    <div className="col-2">
                        <FormControl className='span-4' sx={{ display: "flex", alignSelf: "end", padding: 0, margin: 0 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Colunas Visíveis</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedColumns}
                                onChange={handleChange}
                                input={<OutlinedInput label="Colunas Visíveis" />}
                                renderValue={(selected) => {
                                    const selectedLabels = visibleColumns
                                        .filter(column => selected.includes(column.id))
                                        .map(column => column.label);
                                    return selectedLabels.join(', ');
                                }}
                            >
                                {visibleColumns.map((column) => (
                                    <MenuItem key={column.id} value={column.id}>
                                        <Checkbox checked={selectedColumns.includes(column.id)} />
                                        <ListItemText primary={column.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Operadora"
                            type="search"
                            className="span-6"
                            placeholder="Pesquisar..."
                            onSearch={searchOperadora}
                            name="id_operadora"
                            model={(...params) => {
                                const idOperadora = params[0]?.id;
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_operadora: idOperadora
                                }));
                            }}
                        />
                    </div>
                    <div className="col-2" style={{ width: '300px' }}>
                        <InputForm
                            label="Especialidade"
                            type="search"
                            placeholder="Pesquisar..."
                            className="span-3"
                            onSearch={searchEspecialidades}
                            name="id_especialidade"
                            model={(...params) => {
                                const idEspecialidade = params[0]?.id;
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    especialidade: idEspecialidade
                                }));
                            }}
                        />
                    </div>
                    <div className="col-2" style={{ width: '300px' }}>
                        <InputForm
                            label="Área de atuação"
                            onChange={() => { }}
                            type="search"
                            placeholder={(form.id_especialidade) ? "Pesquisar..." : "Selecione a especialidade"}
                            className="span-4"
                            onSearch={searchAreaAtuacao}
                            name="id_area_atuacao"
                            model={(...params) => {
                                const idAreaAtuacao = params[0]?.id;
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    areaAtuacao: idAreaAtuacao
                                }));
                            }}
                            value={(form.id_especialidade) ? form.desc_area_atuacao : ''}
                            disabled={!filtrosProcessos.especialidade} />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Profissional desempatador"
                            type="search"
                            placeholder="Pesquisar..."
                            className="span-3"
                            name="profissional_desempatador"
                            onSearch={searchDesempatadores}
                            model={(...params) => {
                                const idDesempatador = params[0]?.id;
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_desempatador: idDesempatador
                                }));
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="flex ai-fc jc-sb mb-60">
                <div className="flex ai-fc gap-25">
                    <div className="col-2">
                        <InputForm
                            label="Profissional assistente"
                            type="search"
                            placeholder="Pesquisar..."
                            className="span-3"
                            name="profissional_assistente"
                            onSearch={searchAssistentes}
                            model={(...params) => {
                                const idAssistente = params[0]?.nome;
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_assistente: idAssistente
                                }));
                            }}
                        />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Etapa"
                            className="span-3"
                            name="id_processo_etapa"
                            type="select"
                            value={filtrosProcessos.id_processo_etapa}
                            options={options?.etapas}
                            model={setModel}
                        />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Status"
                            className="span-3"
                            name="id_processo_etapa_status"
                            type="select"
                            value={filtrosProcessos.id_processo_etapa_status}
                            options={options?.status}
                            model={setModel} />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Tipo de processo"
                            required
                            type="select"
                            className="gap-25"
                            name="id_type_user"
                            options={typeProcess}
                            value={filtrosProcessos?.typeProcess} // Ajustado para refletir o ID corretamente
                            model={(name, id) => {
                                const selectedProcess = typeProcess.find(process => process.id === Number(id)); // Converte id para número
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    typeProcess: selectedProcess?.id || '' // Garante que sempre tenha um valor válido
                                }));
                            }}
                        />
                    </div>
                    <div>
                        <InputForm className='span-2 input-padding' label="Data início" type="date" id="inicio_processo" name="inicio_processo" model={setModel} value={filtrosProcessos.inicio_processo} />
                    </div>
                    <div>
                        <InputForm className='span-2 input-padding' label="Data final" type="date" id="data_conclusao" name="data_conclusao" model={setModel} value={filtrosProcessos.data_conclusao} />
                    </div>
                    <span className="pointer float-right ml-20 mt-30" onClick={limparFiltros}>
                        <i className="icon icon-trash watermelon i-25" title="Limpar filtros" />
                    </span>
                </div>
            </div>
            <div>
                {loading ? <div className="loader loader-default is-active" /> : (<>
                    <div className="table-mobile">
                        {processos.map((p, i) => {
                            return <div className="item pointer" key={`mobile-${i}`}>
                                <div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Protocolo <span>{p.id_protocolo}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Data de início <span>{tools.formatDate(p.inicio_processo) || '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Data de conclusão <span>{p.data_conclusao ? tools.formatDate(p.data_conclusao) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Tipo de processo <span>{p.desc_processo_tipo}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Especialidade <span>{p.desc_especialidade}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Operadora <span>{p.desc_operadora}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Plano <span>{p.plano ? p.plano : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Beneficiário <span>{p.nome_beneficiario}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        CPF <span>{p.cpf_beneficiario}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Nº guia <span>{p.guia}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Profissional assistente <span>Dr.(a) {p.profissional_assistente}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        CRM/CRO <span>{p.profissional_assistente_crm}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Desempatador <span>{p.nome_desempatador}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Assistente <span>{p.assistente_saasto}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Enfermeira <span>{p.enfermeiro_saasto}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Etapa <span>{p.desc_processo_etapa}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Status <span>{p.desc_processo_etapa_status}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Conclusão <span>{p.processo_conclusao}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Valor inicial procedimentos <span>{p.total_procedimentos_inicial ? tools.formatCurrency(p.total_procedimentos_inicial) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Valor inicial materiais <span>{p.total_materiais_inicial ? tools.formatCurrency(p.total_materiais_inicial) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        <strong>Valor inicial total</strong> <span> <strong>{p.total_inicial ? tools.formatCurrency(p.total_inicial) : '-'}</strong></span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Valor final procedimentos <span>{p.total_procedimentos_final ? tools.formatCurrency(p.total_procedimentos_final) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        Valor final materiais <span>{p.total_materiais_final ? tools.formatCurrency(p.total_materiais_final) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb">
                                        <strong>Valor final total</strong> <span><strong>{p.total_final ? tools.formatCurrency(p.total_final) : '-'}</strong></span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb" style={{ color: '#24BE34' }}>
                                        <strong>Economizado em procedimentos</strong> <span style={{ color: '#24BE34' }}>{p.total_economizado_procedimentos ? tools.formatCurrency(p.total_economizado_procedimentos) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb" style={{ color: '#24BE34' }}>
                                        <strong>Economizado em materiais</strong> <span style={{ color: '#24BE34' }}>{p.total_economizado_materiais ? tools.formatCurrency(p.total_economizado_materiais) : '-'}</span>
                                    </div>
                                    <div className="fn-16 linha flex jc-sb" style={{ color: '#24BE34' }}>
                                        <strong>Total economizado</strong> <span style={{ color: '#24BE34' }}><strong>{p.total_economizado ? tools.formatCurrency(p.total_economizado) : '-'}</strong></span>
                                    </div>
                                </div>
                                <div className="w-100 flex jc-fc">
                                    -
                                </div>
                            </div>
                        })}
                        {(!processos || !processos?.length) && <div className="item pointer" key={`mobile-0`}>
                            Nenhum resultado encontrado.
                        </div>}
                    </div>
                    <table className="w-100 table-desktop" style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead className="f300-16">
                            <tr>
                                {visibleColumns.map(column => column.visible && (
                                    <th key={column.id} className="text-left bold" style={{ padding: '4px', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>{column.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {processos.map((p, i) => (
                                <tr key={`desktop-${i}`} className="hover-highlight">
                                    {visibleColumns.map(column => column.visible && (
                                        <td key={column.id} className="fn-16 text-left" style={{ padding: '4px', border: '1px solid #ddd' }}>
                                            {formatValue(column.id, p)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            {(!processos || !processos.length) && <tr>
                                <td style={{ textAlign: 'center', padding: '8px' }} colSpan={visibleColumns.filter(col => col.visible).length}>Nenhum resultado encontrado.</td>
                            </tr>}
                        </tbody>
                    </table>
                    <div className="component pagination">
                        <div className="pagination-container mt-30">
                            {pager.total_pages < 2 ? '' : <ReactPaginate
                                previousLabel="«"
                                nextLabel="»"
                                breakLabel="..."
                                breakClassName="break-me"
                                page={pager.page - 1 ?? 0}
                                forcePage={pager.page - 1 ?? 0}
                                pageCount={pager.total_pages}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                subContainerClassName="pages pagination"
                                activeClassName="active" />}
                        </div>
                    </div>
                </>)}
            </div>
        </Layout >
    );
}

