//import Cep from "cep-promise";
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import business from '../../services/business.js';
import InputForm from "../Form/InputForm.js";
import Loading from '../Loading.js';
import Modal from '../Modal';
import config from '../../config/config.js';
import { useAlert } from 'react-alert';

export default function ModalResponsaveis({ hideModal, show, processo = {} }) {

    const alert = useAlert();

    const salvar = async (e) => {

        const body = document.body;

        const data = {
            id_assistente: filtros.assistente,
            id_enfermeira: filtros.enfermeira,
            id_desempatador: filtros.desempatador,
        };

        try {
            const response = await business.putResponsaveis(data, processo?.id);
            if (response) {
                body.classList.remove('overflow-hidden');
                alert.success('Responsável alterado com sucesso.');
                hideModal(e); // Fecha o modal ao concluir
            }
        } catch (error) {
            console.error("Erro ao salvar os responsáveis:", error);
            alert("Não foi possível salvar os responsáveis. Tente novamente.");
        }
    };

    const [filtros, setFiltros] = useState({});

    const setModel = (name, value) => {
        setFiltros({
            ...filtros, [name]: value.id,
        });
    };

    const searchAssistente = async (e) => {
        return await business.getResponsaveisAssistente(e.target.value);
    }

    const searchEnfermeira = async (e) => {
        return await business.getResponsaveisEnfermeira(e.target.value);
    }

    const searchDesempatador = async (e) => {
        return await business.getResponsaveisDesempatador(processo?.id, e.target.value);
    }

    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        const fetchResponsaveis = async () => {
            if (!processo?.id) return;
            if (parseInt(config.user.tipo) === 2 || parseInt(config.user.tipo) === 3 || parseInt(config.user.tipo) === 6) {
                setDisable(true);
            }

            setLoading(true);

            try {
                const [desempatadores, assistentes, enfermeiras, responsaveis] = await Promise.all([
                    business.getResponsaveisDesempatador(processo?.id),
                    business.getResponsaveisAssistente(),
                    business.getResponsaveisEnfermeira(),
                    business.getResponsaveis(processo?.id)
                ]);

                const listaDesempatadores = Array.isArray(desempatadores)
                    ? [{ id: 0, nome: "Não definido" }, ...desempatadores.map(item => ({ id: item.id, nome: item.nome }))]
                    : [{ id: 0, nome: "Não definido" }];

                const listaAssistentes = Array.isArray(assistentes)
                    ? [{ id: 0, nome: "Não definido" }, ...assistentes.map(item => ({ id: item.id, nome: item.nome }))]
                    : [{ id: 0, nome: "Não definido." }];

                const listaEnfermeiras = Array.isArray(enfermeiras)
                    ? [{ id: 0, nome: "Não definido" }, ...enfermeiras.map(item => ({ id: item.id, nome: item.nome }))]
                    : [{ id: 0, nome: "Não definido" }];

                setFiltros({
                    listaDesempatadores,
                    listaAssistentes,
                    listaEnfermeiras
                });

                if (responsaveis) {
                    setFiltros({
                        assistente: responsaveis.nome_assistente,
                        enfermeira: responsaveis.nome_enfermeira,
                        desempatador: responsaveis.nome_desempatador,
                    });
                }

            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchResponsaveis();
    }, [processo?.id]);

    return (
        <Modal show={show} handleClose={hideModal} style={{ maxWidth: '600px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">Responsáveis</h1>
                    {loading ? (<Loading />) : (<div>
                        <div className="group-input f400-18 pr-40">
                            <InputForm className="mb-30" name="assistente" value={filtros.assistente} disabled={disable} label="Assistente" type="search" model={(...params) => setModel('assistente', ...params)} onSearch={searchAssistente} placeholder={'Escolha um profissional..'} />
                        </div>
                        <div className="group-input f400-18 pr-40">
                            <InputForm className="mb-30" name="enfermeira" value={filtros.enfermeira} disabled={disable} label="Enfermeira" type="search" model={(...params) => setModel('enfermeira', ...params)} onSearch={searchEnfermeira} placeholder={'Escolha um profissional..'} />
                        </div>
                        <div className="group-input f400-18 pr-40">
                            <InputForm className="mb-30" disabled={disable} value={filtros.desempatador} name="desempatador" label="Especialista/Desempatador" type="search" model={(...params) => setModel('desempatador', ...params)} onSearch={searchDesempatador} placeholder={'Escolha um profissional..'} />
                        </div>
                    </div>)}
                    {<div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={hideModal}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16 mr-40" onClick={salvar} disabled={disable}>
                            Salvar
                        </button>
                    </div>}
                </div>

            </div>
        </Modal>
    );
}
