import { useEffect, useState } from 'react';
import InputForm from '../Form/InputForm';
import Modal from '../Modal';
import ToggleSwitch from '../ToggleSwitch';
import business from '../../services/business';
import { useAlert } from 'react-alert';
import Loading from '../Loading.js';

export default function ModalProcedimentoAdmin({ hideModal, show, dto = {}, onSaveSuccess }) {

    const [form, setForm] = useState([]);
    const [loading, setLoading] = useState(false);
    const alert = useAlert();
    const body = document.body;
    const setModel = (name, value) => {
        setForm({ ...form, ...{ [name]: value } });
    }

    const getProcedimento = async () => {
        setLoading(true);
        try {
            if (dto?.id) {
                const response = await business.getProcedimentoById(dto.id);
                setForm(response);
            }
        } catch (error) {
            console.error("Erro ao buscar o procedimento:", error);
            alert("Não foi possível buscar o procedimento. Tente novamente.");
        } finally {
            setLoading(false);
        }
    };

    const setModelCheck = (name, checked) => {
        setForm({ ...form, ...{ [name]: (checked) ? 1 : 0 } });
    }

    const salvar = async (e) => {
        try {
            const response = await business.addProcedimentoAdmin(form, dto?.id);

            if (response && response.response?.status) {
                body.classList.remove('overflow-hidden');
                alert.success('Procedimento salvo com sucesso.');
                hideModal(e);
                onSaveSuccess();
            } else if (response && response?.errors?.length) {
                alert.error(response?.errors[0]);
            }
        } catch (error) {
            console.error("Erro ao salvar o procedimento:", error);
            alert("Não foi possível salvar o procedimento. Tente novamente.");
        }
    }

    const handleClose = (e) => {
        const body = document.body;
        body.classList.remove('overflow-hidden');
        hideModal(e);
    }

    useEffect(() => {
        getProcedimento();
        // eslint-disable-next-line
    }, [dto]);

    return (
        <Modal show={show} handleClose={handleClose} style={{ maxWidth: '1000px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <h1 className="title mb-45">{dto?.id ? 'Editar Procedimento' : 'Adicionar Procedimento'}</h1>
                    {loading ? (<Loading />) : (<div className={`form-container grid col-12`}>
                        <InputForm label="Código" type="text" mask={'###########'}
                            name="codigo" required className="span-3" maxLength={11} value={form?.codigo} model={setModel} />
                        <InputForm label="Descrição" type="text"
                            name="desc" required className="span-9" maxLength={64} value={form?.desc?.toUpperCase()} model={setModel} />
                        <InputForm label="Valor" type="decimal"
                            name="valor" className="span-3" value={form?.valor} model={setModel} />
                        <InputForm label="Cobertura ROL" type="select" options={[
                            { id: 1, value: 1, label: 'Sim', desc: 'Sim' },
                            { id: 0, value: 0, label: 'Não', desc: 'Não' },
                        ]} name="cobertura_rol" required className="span-3" value={form?.cobertura_rol} model={setModel} />
                        <InputForm label="DUT" type="select" options={[
                            { id: 1, value: 1, label: 'Sim', desc: 'Sim' },
                            { id: 0, value: 0, label: 'Não', desc: 'Não' },
                        ]} name="dut" required className="span-3" value={form.dut} model={setModel} />
                        <div className="span-3 mt-30">
                            <div className="fn-16 linha flex jc-sb">
                                <span>
                                    <ToggleSwitch type="checkbox" id="status" name="status" value={Number(form['status']) === 1 ? 1 : 0}
                                        texto={['Ativo', 'Inativo']} style={{ '--width': '110px' }}
                                        model={setModelCheck}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>)}
                    <div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16 mr-40" onClick={salvar}  >
                            {dto?.id ? 'Editar' : 'Salvar'}
                        </button>
                    </div>
                </div>

            </div>
        </Modal>
    );
}
