import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout.js";
import MenuDots from "../../components/MenuDots.js";
import ModalUsuario from "../../components/Modais/ModalUsuario.js";
import Search from "../../components/Form/Search.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import "../../assets/styles/pages/Usuarios.scss";
import "pure-css-loader/src/loader-default.sass";
import business from "../../services/business.js";
import { useAlert } from "react-alert";
import layout from "../../lib/layout.js";
import config from "../../config/config.js";
import InputForm from "../../components/Form/InputForm.js";

export default function UsuariosEspecialistas() {
  document.title = "säästo";
  layout.openMenu("/usuarios/especialistas", true);
  const [pager, setPager] = useState({
    page: 1,
    total_pages: 0,
    total_rows: 0,
  });

  const [listagem, setListagem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [usuario, setUsuario] = useState({});
  const alert = useAlert();
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroEspecialidade, setFiltroEspecialidade] = useState("");
  const [filtroAreaAtuacao, setFiltroAreaAtuacao] = useState("");

  const searchEspecialidades = async (e) => {
    return await business.getEspecialidades(e.target.value, 5, 1, form.id_area_atuacao);
  }

  const searchAreaAtuacao = async (e) => {
    return await business.getAreaAtuacao(e.target.value, 12, 1, form.id_especialidade);
  }

  const setSearch = (name, data) => {
    setForm({
      ...form,
      ...{
        [`id_${name}`]: data.id,
        [`nome_${name}`]: data.nome,
      },
    });
  };

  const [form, setForm] = useState({});

  const openModal = async (event = {}, row = {}) => {
    event?.preventDefault();
    if (row?.id) setUsuario(row);
    setShowModal(true);
  };

  const hideModal = async (event, reload = false) => {
    event?.preventDefault();
    setShowModal(false);
    setUsuario({});
    if (reload) {
      await getSearch();
    }
  };

  const menuItens = [
    {
      label: "Editar",
      icon: "icon-edit blue i-25",
      className: "menu-icon",
      onClick: openModal,
      disabled: !config.temPermissao("admin:usuarios:editar"),
    },
    {
      label: "Remover",
      icon: "icon-trash blue i-25",
      className: "menu-icon",
      loader: true,
      onClick: async (e, usuario) => {
        e.stopPropagation();
        if (
          !usuario?.id ||
          !window.confirm("Deseja realmente remover o usuário?")
        ) {
          return false;
        }
        let request = await business.removerUsuario(usuario.id);
        if (request && request.response?.status) {
          getSearch();
        } else if (request && request?.errors?.length) {
          alert.error(request?.errors[0]);
        }
      },
      disabled: !config.temPermissao("admin:usuarios:excluir"),
    },
  ];

  const handlePageClick = (e) => {
    getSearch(e.selected + 1);
  };

  const getSearch = async (page = null, texto = filtroTexto, especialidade = filtroEspecialidade, areaAtuacao = filtroAreaAtuacao, loading = true) => {
    setLoading(loading);
    let usuarios = await business.getAllUsuarios(
      page === null ? pager.page : page,
      10,
      texto,
      [6],
      especialidade,
      areaAtuacao
    );
    setListagem(usuarios?.response || []);
    if (
      usuarios?.response &&
      pager.page &&
      usuarios?.pager?.paginas > 0 &&
      usuarios?.pager?.paginas < pager.page
    ) {
      pager.page = 1;
      return getSearch();
    }
    pager.page = usuarios?.pager?.pagina;
    setPager({
      page: usuarios?.pager?.pagina,
      total_pages: usuarios?.pager?.paginas,
      total_rows: usuarios?.pager?.total,
    });
    setLoading(false);
    setSearchLoading(false);
  };

  const handleWindowSizeChange = (mobileSize) => {
    let checkIsMobile = window.innerWidth < mobileSize;
    setIsMobile(checkIsMobile);
  };

  const formatPhone = (value) => {
    if (!value) return value;
    const phonePattern = /^(\d{0,2})(\d{0,5})(\d{0,4})$/;
    const matches = value.replace(/\D/g, "").match(phonePattern);
    const part1 = matches[1] ? `(${matches[1]}) ` : "";
    const part2 = matches[2] ? `${matches[2]}` : "";
    const part3 = matches[3] ? `-${matches[3]}` : "";
    return `${part1}${part2}${part3}`.trim();
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      return handleWindowSizeChange(1180);
    });
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getSearch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="view-listagem">
      {showModal && (
        <ModalUsuario show={showModal} hideModal={hideModal} dto={usuario} />
      )}
      <p className="page-title">
        Usuários / <span className="blue-text">Especialistas</span>
      </p>
      <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
        Usuários de especialistas
        {config.temPermissao("admin:usuarios:cadastrar") && (
          <button
            className="btn btn-primary f700-16 mobile"
            onClick={openModal}
          >
            Cadastrar
          </button>
        )}
      </h2>
      <div className="grid flex jc-sb ai-fc mb-60 md-gap">
        <div className="grid flex ai-fc sm-gap">
          <div className="container-search-filter ai-fc jc-sb" style={{ alignSelf: "flex-end" }}>
            <Search
              onChange={(value) => {
                setSearchLoading(true);
                setFiltroTexto(value);
                getSearch(1, value, filtroEspecialidade, filtroAreaAtuacao, false);
              }}
              searchOnTyping={isMobile}
              loading={searchLoading}
              placeholder={'Pesquisa...'}
            />
          </div>
          <div className="col-2" style={{ width: '300px' }}>
            <InputForm
              label="Especialidade"
              type="search"
              placeholder="Pesquisar..."
              className="span-3"
              onSearch={searchEspecialidades}
              name="id_especialidade"
              model={(...params) => {
                const idEspecialidade = params[0]?.id;
                setFiltroEspecialidade(idEspecialidade);
                setSearch('especialidade', ...params);
                getSearch(1, filtroTexto, idEspecialidade, filtroAreaAtuacao, true);
              }}
            />
          </div>
          <div className="col-2" style={{ width: '300px' }}>
            <InputForm
              label="Área de atuação"
              onChange={() => { }}
              type="search"
              placeholder={(form.id_especialidade) ? "Pesquisar..." : "Selecione a especialidade"}
              className="span-4"
              onSearch={searchAreaAtuacao}
              name="id_area_atuacao"
              model={(...params) => {
                const idAreaAtuacao = params[0]?.id;
                setFiltroAreaAtuacao(idAreaAtuacao);
                setSearch('area_atuacao', ...params);
                getSearch(1, filtroTexto, filtroEspecialidade, idAreaAtuacao, true);
              }}
              value={(form.id_especialidade) ? form.desc_area_atuacao : ''}
              disabled={!form.id_especialidade} />
          </div>
        </div>
        <div className="flex ai-fc gap-30 desktop" style={{ alignSelf: "flex-end" }}>
          {config.temPermissao("admin:usuarios:cadastrar") && (
            <button className="btn btn-primary f700-16" onClick={openModal}>
              Cadastrar
            </button>
          )}
        </div>
      </div>

      {
        loading ? (
          <div className="loader loader-default is-active" />
        ) : (
          <div>
            <div className="table-mobile">
              {listagem.map((p, i) => {
                return (
                  <div className="item pointer" key={`mobile-${i}`}>
                    <div>
                      <div className="fn-16 linha flex jc-sb">
                        Nome
                        <span>{p.nome}</span>
                      </div>
                      <div className="fn-16 linha flex jc-sb">
                        Telefone
                        <span>{formatPhone(p.telefone) || "-"}</span>
                      </div>
                      <div className="fn-16 linha flex jc-sb">
                        E-mail
                        <span>{p.email}</span>
                      </div>
                      <div className="fn-16 linha flex jc-sb">
                        Perfil de Acesso
                        <span
                          className={`tag-perfil ${(
                            p.perfil || ""
                          ).toLowerCase()}`}
                        >
                          {p.perfil}
                        </span>
                      </div>
                      <div className="fn-16 linha flex jc-sb">
                        Status
                        <span>
                          <ToggleSwitch
                            type="checkbox"
                            id={"mobile-" + p.id}
                            name="status"
                            disabled={
                              parseInt(p.id_usuarios_tipo) === 1 ||
                              !config.temPermissao("admin:usuarios:editar")
                            }
                            value={parseInt(p.id_status) === 1 ? true : false}
                            texto={["Ativo", "Inativo"]}
                            style={{ "--width": "110px" }}
                            model={async (name, data) => {
                              listagem[i].id_status = data ? 1 : 2;
                              let request = await business.statusUsuario(
                                listagem[i].id_status,
                                listagem[i].id
                              );
                              if (request?.response?.status) {
                                setListagem([...listagem]);
                              }
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="w-100 flex jc-fc">
                      <MenuDots
                        row={p}
                        menuItens={menuItens}
                        menuPositionTop={true}
                      />
                    </div>
                  </div>
                );
              })}
              {(!listagem || !listagem?.length) && (
                <div className="item pointer" key={`mobile-0`}>
                  Nenhum resultado encontrado.
                </div>
              )}
            </div>
            <table className="w-100 table-desktop">
              <thead className="f300-16">
                <tr>
                  <th className="bold">Nome</th>
                  <th className="text-left bold">Telefone</th>
                  <th className="text-left bold">E-mail</th>
                  <th className="text-center bold">Perfil de Acesso</th>
                  <th className="text-center bold">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listagem.map((p, i) => {
                  return (
                    <tr key={`desktop-${i}`}>
                      <td className="fn-16">{p.nome}</td>
                      <td className="fn-16">{formatPhone(p.telefone) || "-"}</td>
                      <td className="fn-16 underline">{p.email}</td>
                      <td className="fn-16 text-center">
                        <span
                          className={`tag-perfil ${(
                            p.desc_usuarios_tipo || ""
                          ).toLowerCase()}`}
                        >
                          {p.desc_usuarios_tipo}
                        </span>
                      </td>
                      <td className="fn-16 text-center" style={{ minWidth: "120px" }}>
                        <ToggleSwitch
                          type="checkbox"
                          id={"desktop-" + p.id}
                          name="status"
                          disabled={
                            parseInt(p.id_usuarios_tipo) === 1 ||
                            !config.temPermissao("admin:usuarios:editar")
                          }
                          value={parseInt(p.id_status) === 1 ? true : false}
                          texto={["Ativo", "Inativo"]}
                          style={{ "--width": "110px" }}
                          model={async (name, data) => {
                            listagem[i].id_status = data ? 1 : 2;
                            let request = await business.statusUsuario(
                              listagem[i].id_status,
                              listagem[i].id
                            );
                            if (request?.response?.status) {
                              setListagem([...listagem]);
                            }
                          }}
                        />
                      </td>
                      <td className="text-center" style={{ width: "35px" }}>
                        <MenuDots
                          row={p}
                          menuItens={menuItens}
                          menuPositionTop={listagem.length - i <= 2}
                        />
                      </td>
                    </tr>
                  );
                })}
                {(!listagem || !listagem?.length) && (
                  <tr>
                    <td colSpan={7}>Nenhum resultado encontrado.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="component pagination">
              <div className="pagination-container mt-30">
                {pager.total_pages < 2 ? (
                  ""
                ) : (
                  <ReactPaginate
                    previousLabel="«"
                    nextLabel="»"
                    breakLabel="..."
                    breakClassName="break-me"
                    page={pager.page - 1 ?? 0}
                    forcePage={pager.page - 1 ?? 0}
                    pageCount={pager.total_pages}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                )}
              </div>
            </div>
          </div>
        )
      }
    </Layout >
  );
}
