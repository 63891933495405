import Modal from '../Modal.js';

export default function ModalReenvioComunicado({ hideModal, show, avancarEtapa }) {

    const salvar = async (e) => {
        avancarEtapa(); // Chamar avancarEtapa após salvar
        hideModal(e);
    }

    const handleClose = (e) => {
        const body = document.body;
        body.classList.remove('overflow-hidden');
        hideModal(e);
    }

    return (
        <Modal show={show} handleClose={handleClose} style={{ maxWidth: '1000px', maxHeight: '600px' }} modalClass="modal-cad-usuario">
            <div className="flex">
                <div className="form w-100 mb-50">
                    <div className={`form-container grid`}>
                        <div className="span-3 mt-30">
                            <div className="fn-16 linha flex jc-sb">
                                <p>Essa comunicação já foi enviada, deseja realmente reenviar?</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-rodape mt-30 ">
                        <button className="btn btn-outline-primary f700-16" onClick={handleClose}>
                            Cancelar
                        </button>
                        <button className="btn btn-primary f700-16 mr-40" onClick={salvar}  >
                            Reenviar
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}