import React from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, Navigate } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import Login from "../pages/Login.js";
import Dashboard from "../pages/Dashboard.js";
import Processo from "../pages/Processo/Index.js";
import CadProcesso from "../pages/Processo/Cadastro.js";
import DetProcesso from "../pages/Processo/Detalhes.js";
import { isAuthenticated } from "../services/auth";
import config from "./config.js";
import EmailPage from '../pages/EmailPage.js';
import ErrorPage from '../pages/404.js';
import Financeiro from '../pages/Relatorios/Financeiro.js';
import EmailCheck from '../pages/EmailCheck.js';
import DocDossie from '../pages/Docs/Dossie.js';
import DocParecer from '../pages/Docs/Parecer.js';
import DocParecerSegundaOpiniao from '../pages/Docs/ParecerSegundaOpiniao.js';
import DocConsenso from '../pages/Docs/Consenso.js';
import PowerBI from '../pages/Relatorios/PowerBI.js';
import FAQ from '../pages/Ajuda/FAQ.js';
import DocsOperacionais from '../pages/Ajuda/DocsOperacionais.js';
import DesempatadorManualTecnico from '../pages/Ajuda/DesempatadorManualTecnico.js';
import DesempatadorManualUtilizacao from '../pages/Ajuda/DesempatadorManualUtilizacao.js';
import OperadoraManualUtilizacao from '../pages/Ajuda/OperadoraManualUtilizacao.js';
import DesempatadorManualInstrucoes from '../pages/Ajuda/DesempatadorManualInstrucoes.js';
import OperadoraEtapasStatus from '../pages/Ajuda/OperadoraEtapasStatus.js';
import TotalEconomizado from '../pages/Relatorios/TotalEconomizado.js';
import Usuarios from "../pages/Usuarios/Usuarios.js";
import UsuariosOperadoras from '../pages/Usuarios/Operadoras.js';
import UsuariosEspecialistas from '../pages/Usuarios/Especialistas.js';
import Operadoras from '../pages/Administracao/Operadoras.js';
import Materiais from '../pages/Administracao/Materiais.js';
import Procedimentos from '../pages/Administracao/Procedimentos.js';
import Suporte from '../pages/Suporte.js';

const ProtectedRoute = ({ redirectPath = '/login', children }) => {
  if (!isAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }
  config.user = JSON.parse(localStorage.getItem('user') || '{}');
  config.permissoes = JSON.parse(localStorage.getItem('permissoes') || '[]');
  return children;
}

function Routes() {
  const options = {
    position: positions.TOP_RIGHT,
    timeout: 10000,
    offset: '10px 10px 0 10px',
    transition: transitions.FADE
  }
  const AlertTemplate = ({ style, options, message, close }) => (
    <div style={style} className={`alert alert-${options.type}`}>
      <div className="alert-message">{message}</div>
      <div className="alert-close">
        <button type="button" className="close pointer" onClick={close}>
          <i className="icon icon-cross-small i-25 watermelon" />
        </button>
      </div>
    </div>
  )
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Switch>
          <Route path="/login" exact element={<Login />} />
          <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />


          <Route path="/opiniao-especializada" element={<ProtectedRoute><Processo name="opiniao-especializada" id="1" /></ProtectedRoute>} />
          <Route path="/opiniao-especializada/:id" element={<ProtectedRoute><DetProcesso name="opiniao-especializada" /></ProtectedRoute>} />

          <Route path="/consenso-pre" element={<ProtectedRoute><Processo name="consenso-pre" id="2" /></ProtectedRoute>} />
          <Route path="/consenso-pre/:id" element={<ProtectedRoute><DetProcesso name="consenso-pre" /></ProtectedRoute>} />

          <Route path="/junta-medica" element={<ProtectedRoute><Processo name="junta-medica" id="3" /></ProtectedRoute>} />
          <Route path="/junta-medica/novo" element={<ProtectedRoute><CadProcesso name="junta-medica" /></ProtectedRoute>} />
          <Route path="/junta-medica/:id" element={<ProtectedRoute><DetProcesso name="junta-medica" /></ProtectedRoute>} />

          <Route path="/novo-processo" element={<ProtectedRoute><CadProcesso name="novo-processo" /></ProtectedRoute>} />

          <Route path="/:id_processo/:id_processo_etapa_status/dossie" element={<ProtectedRoute><DocDossie /></ProtectedRoute>} />
          <Route path="/:id_processo/parecer" element={<ProtectedRoute><DocParecer /></ProtectedRoute>} />
          <Route path="/:id_processo/parecer-segunda-opiniao" element={<ProtectedRoute><DocParecerSegundaOpiniao /></ProtectedRoute>} />
          <Route path="/:id_processo/consenso" element={<ProtectedRoute><DocConsenso /></ProtectedRoute>} />

          <Route path="/comunicacao/:id/:id_processo" element={<ProtectedRoute><EmailCheck /></ProtectedRoute>} />

          <Route path="/relatorios/bi" element={<ProtectedRoute><PowerBI /></ProtectedRoute>} />
          <Route path="/relatorios/total-economizado" element={<ProtectedRoute><TotalEconomizado name="relatorio-total-economizado" /></ProtectedRoute>} />
          <Route path="/relatorios/financeiro" element={<ProtectedRoute><Financeiro name="relatorio-financeiro" /></ProtectedRoute>} />

          <Route path="/usuarios/saasto" element={<ProtectedRoute><Usuarios /></ProtectedRoute>} />
          <Route path="/usuarios/especialistas" element={<ProtectedRoute><UsuariosEspecialistas /></ProtectedRoute>} />
          <Route path="/usuarios/operadoras" element={<ProtectedRoute><UsuariosOperadoras /></ProtectedRoute>} />

          <Route path="administracao/operadoras" element={<ProtectedRoute><Operadoras /></ProtectedRoute>} />
          <Route path="administracao/procedimentos" element={<ProtectedRoute><Procedimentos /></ProtectedRoute>} />
          <Route path="administracao/materiais" element={<ProtectedRoute><Materiais /></ProtectedRoute>} />

          <Route path="/suporte" element={<ProtectedRoute><Suporte /></ProtectedRoute>} />

          <Route path="/ajuda/faq" element={<ProtectedRoute><FAQ /></ProtectedRoute>} />
          <Route path="/ajuda/documentos-operacionais" element={<ProtectedRoute><DocsOperacionais /></ProtectedRoute>} />
          <Route path="/ajuda/desempatador-manual-instrucoes" element={<ProtectedRoute><DesempatadorManualInstrucoes /></ProtectedRoute>} />
          <Route path="/ajuda/desempatador-manual-utilizacao" element={<ProtectedRoute><DesempatadorManualUtilizacao /></ProtectedRoute>} />
          <Route path="/ajuda/operadora-manual-utilizacao" element={<ProtectedRoute><OperadoraManualUtilizacao /></ProtectedRoute>} />
          <Route path="/ajuda/desempatador-manual-tecnico" element={<ProtectedRoute><DesempatadorManualTecnico /></ProtectedRoute>} />
          <Route path="/ajuda/operadora-etapas-status" element={<ProtectedRoute><OperadoraEtapasStatus /></ProtectedRoute>} />

          <Route path="/externo/id/:id/:hash" element={<EmailPage />} />

          <Route path="*" element={<ProtectedRoute><ErrorPage /></ProtectedRoute>} />

        </Switch>
      </Router>
    </AlertProvider>
  );
};

export default Routes;