import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout.js";
import '../../assets/styles/pages/Usuarios.scss'
import 'pure-css-loader/src/loader-default.sass';
import business from "../../services/business.js";
import layout from '../../lib/layout.js';
import config from '../../config/config.js';
import tools from '../../lib/tools.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Loading from '../../components/Loading.js';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import InputForm from '../../components/Form/InputForm.js';

export default function Financeiro() {

    let user = config.user;
    document.title = 'säästo';
    layout.openMenu('/usuarios', true);
    const [pager, setPager] = useState({
        page: 1,
        total_pages: 0,
        total_rows: 0,
    });

    const [visibleColumns, setVisibleColumns] = useState([
        { id: 'id_protocolo', label: 'Protocolo', visible: true },
        { id: 'data_conclusao', label: 'Data de Conclusão', visible: true },
        { id: 'desc_processo_tipo', label: 'Tipo de Processo', visible: true },
        { id: 'desc_operadora', label: 'Operadora', visible: true },
        { id: 'plano', label: 'Plano', visible: true },
        { id: 'guia', label: 'Guia', visible: true },
        { id: 'nome', label: 'Beneficiário', visible: true },
        { id: 'carteirinha', label: 'Carteirinha', visible: true },
        { id: 'nome_desempatador', label: 'Especialista', visible: true },
        { id: 'crm_cro', label: 'CRM/CRO', visible: true },
        { id: 'desc_processo_etapa_status', label: 'Status', visible: true },
        { id: 'processo_conclusao', label: 'Conclusão', visible: false },
        { id: 'tipo_analise', label: 'Tipo análise', visible: false },
        { id: 'valor_operadora', label: 'Valor operadora', visible: false },
        { id: 'valor_desempatador', label: 'Valor especialista', visible: false },
    ]);

    const [filtros] = useState({
        id_operadora: (!config.isAdmin() && !user.tipo === '7') ? user?.id_operadora : '',
    });

    const [selectedColumns, setSelectedColumns] = useState(visibleColumns.filter(col => col.visible).map(col => col.id));

    const [filtrosProcessos, setFiltrosProcessos] = useState({
        id_operadora: '',
        id_processo_tipo: '',
        id_processo_tipo_analise: '',
        id_desempatador: '',
        id_processo_conclusao: '',
        id_status: '',
        crm_and_cro: '',
    });

    const setModel = async (name, value) => {
        await setFiltrosProcessos(prev => ({ ...prev, [name]: value }));
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedColumns(value);
        setVisibleColumns(prevColumns =>
            prevColumns.map(column =>
                ({ ...column, visible: value.includes(column.id) })
            )
        );
    };

    const [options, setOptions] = useState({})
    const [loaderPdf, setLoaderPdf] = useState(false);
    const [loaderCsv, setLoaderCsv] = useState(false);
    const [processos, setProcessos] = useState([]);
    /*   const [filtros] = useState({
          id_operadora: (!config.isAdmin()) ? user?.id_operadora : '',
          desc_operadora: (!config.isAdmin()) ? user?.desc_operadora : ''
      });
   */
    const handlePageClick = (e) => {
        getSearch(e.selected + 1);
    };

    const dataExport = async () => {
        return await business.exportRelatorioFinanceiro({
            id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : (filtros?.id_operadora ? filtros.id_operadora : (filtrosProcessos?.id_operadora ? filtrosProcessos.id_operadora : '')),
            id_processo_tipo: filtrosProcessos.typeProcess || '',
            id_processo_tipo_analise: filtrosProcessos.id_processo_tipo_analise || '',
            id_desempatador: filtrosProcessos.id_desempatador || '',
            id_processo_conclusao: filtrosProcessos.id_processo_conclusao || '',
            desc_processo_etapa_status: filtrosProcessos?.id_status || '',
            crm_and_cro: filtrosProcessos?.crm_and_cro || '',
        });
    }

    const searchOperadora = async (e) => {
        e.preventDefault();
        return await business.getOperadora(e.target.value);
    }

    const searchDesempatadores = async (e) => {
        e.preventDefault();
        return await business.getDesempatadores(e.target.value);
    }

    const searchCrmCro = async (e) => {
        e.preventDefault();
        return await business.getCrmCro(e.target.value);
    }

    const getSearch = async (page = null, loading = true) => {
        setLoading(loading);
        let processos = await business.getRelatorioFinanceiro((page === null) ? pager.page : page, 10, {
            id_operadora: (user.tipo === '2' || user.tipo === '3') ? user.id_operadora : (filtros?.id_operadora ? filtros.id_operadora : (filtrosProcessos?.id_operadora ? filtrosProcessos.id_operadora : '')),
            id_processo_tipo: filtrosProcessos.typeProcess || '',
            id_processo_tipo_analise: filtrosProcessos.id_processo_tipo_analise || '',
            id_desempatador: filtrosProcessos.id_desempatador || '',
            id_processo_conclusao: filtrosProcessos.id_processo_conclusao || '',
            desc_processo_etapa_status: filtrosProcessos?.id_status || '',
            crm_and_cro: filtrosProcessos?.crm_and_cro || '',
        });

        setProcessos(processos?.response || [])
        if (processos?.response && pager.page && processos?.pager?.paginas < pager.page) {
            pager.page = 1;
            return getSearch();
        }
        pager.page = processos?.pager?.pagina;
        setPager({
            page: processos?.pager?.pagina,
            total_pages: processos?.pager?.paginas,
            total_rows: processos?.pager?.total
        });
        setLoading(false);
        setSearchLoading(false);
    }

    // eslint-disable-next-line 
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            await getSearch();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const exportToPdf = async () => {
        setLoaderPdf(true);
        let data = (await dataExport())?.response || [];

        // Filtra as colunas visíveis
        const visibleColumnsIds = visibleColumns.filter(col => col.visible).map(col => col.id);
        const filteredData = data.map(elemento => {
            const filteredElement = {};
            visibleColumnsIds.forEach(id => {
                filteredElement[id] = elemento[id];
            });
            return filteredElement;
        });

        const valoresBodyPdf = filteredData.map(elemento => {
            const valores = Object.values(elemento);

            if (valores[1]) {
                valores[1] = tools.formatDate(valores[1]);
            }

            if (valores[9]) {
                valores[9] = 'R$ ' + valores[9];
            }

            if (valores[10]) {
                valores[10] = 'R$ ' + valores[10];
            }

            return valores;
        });

        const doc = new jsPDF({ orientation: 'l' });
        autoTable(doc, { html: '#my-table' })
        const header = visibleColumns.filter(col => col.visible).map(col => col.label);
        const tableStartY = 10;
        autoTable(doc, {
            head: [header],
            body: valoresBodyPdf,
            startY: tableStartY,
            styles: { cellPadding: 2, fontSize: 6 },
            columnStyles: { 0: { halign: 'center' } },
            margin: { top: 10 },
        });
        doc.save('relatorio-financeiro.pdf');
        setLoaderPdf(false);
    };

    const exportToCsv = async () => {
        setLoaderCsv(true);
        let filename = 'relatorio-financeiro.csv';
        let data = (await dataExport())?.response || [];

        // Filtra as colunas visíveis
        const visibleColumnsIds = visibleColumns.filter(col => col.visible).map(col => col.id);
        const filteredData = data.map(elemento => {
            const filteredElement = {};
            visibleColumnsIds.forEach(id => {
                filteredElement[id] = elemento[id];
            });
            return filteredElement;
        });

        const options = { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const numberFormatter = new Intl.NumberFormat('pt-BR', options);

        const processValue = (value) => {
            if (value === null) {
                return '';
            } else if (value instanceof Date) {
                return value.toLocaleString();
            } else if (typeof value === 'number') {
                return numberFormatter.format(value);
            } else if (typeof value === 'string') {
                if (value.startsWith('+')) {
                    return `'${value}`;
                } else {
                    return `"${value.replace(/"/g, '""')}"`;
                }
            }
            return value;
        };

        const csvRows = [visibleColumns.filter(col => col.visible).map(col => col.label)]; // Cabeçalho
        filteredData.forEach((objeto) => {
            csvRows.push(Object.values(objeto).map(processValue));
        });

        const csvFile = csvRows.map((row) => row.join(';')).join('\n');
        const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvFile], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
        setLoaderCsv(false);
    };

    const formatValue = (columnId, processo) => {
        switch (columnId) {
            case 'id_protocolo':
                return processo.id_protocolo;
            case 'data_conclusao':
                return processo.data_conclusao ? tools.formatDate(processo.data_conclusao) : '-';
            case 'jmo_video':
                return processo.jmo_video;
            case 'desc_processo_tipo':
                return processo.desc_processo_tipo;
            case 'desc_operadora':
                return processo.desc_operadora;
            case 'plano':
                return processo.plano ? processo.plano : '-';
            case 'guia':
                return processo.guia;
            case 'nome':
                return processo.nome;
            case 'cpf':
                return processo.cpf;
            case 'carteirinha':
                return processo.carteirinha ? processo.carteirinha : '-';
            case 'nome_desempatador':
                return processo.nome_desempatador ? processo.nome_desempatador : '-';
            case 'crm_cro':
                return processo.crm_cro;
            case 'desc_processo_etapa_status':
                return processo.desc_processo_etapa_status;
            case 'processo_conclusao':
                return processo.processo_conclusao ? processo.processo_conclusao : '-';
            case 'tipo_analise':
                return processo.tipo_analise;
            case 'valor_operadora':
                return processo.valor_operadora ? tools.formatCurrency(processo.valor_operadora) : '-';
            case 'valor_desempatador':
                return processo.valor_desempatador ? tools.formatCurrency(processo.valor_desempatador) : '-';
            default:
                return processo[columnId] ? processo[columnId] : '-';
        }
    };

    const typeAnalise = [
        {
            id: 0,
            nome: "Documental"
        },
        {
            id: 1,
            nome: "Video conferência"
        }
    ];

    const typeProcess = [
        {
            id: 0,
            nome: "Segunda Opinião"
        },
        {
            id: 2,
            nome: "Consenso Pré"
        },
        {
            id: 3,
            nome: "Junta Médica ou Odontológica"
        }
    ];

    const typeConclusao = [
        {
            id: 1,
            nome: "De acordo com a solicitação do profissional assistente."
        },
        {
            id: 2,
            nome: "De acordo com a auditoria da operadora."
        },
        {
            id: 3,
            nome: "De acordo parcialmente com a auditoria da operadora de saúde."
        }
    ]

    const getCombosOPS = async () => {

        let status = [
            { id: 0, nome: 'Todos os status' },
            { id: 7, nome: 'Finalizado' },
            { id: 12, nome: 'Finalizado por Consenso' },
        ];
        setOptions({ ...{ status }, ...options })
    }

    const getCombos = async () => {

        let status = [
            { id: 0, nome: 'Todos os status' },
            { id: 46, nome: 'Cancelado pós parecer' },
            { id: 39, nome: 'Concluído por consenso parcial' },
            { id: 42, nome: 'Finalizado por consenso pré' },
            { id: 12, nome: 'Finalizado por Consenso' },
            { id: 7, nome: 'Processo finalizado' },
            { id: 20405, nome: 'Recusa de consenso' },
        ];
        setOptions({ ...{ status }, ...options })
    }

    const limparFiltros = () => {
        //limpar campos dos filtros e fazer nova busca
        /*     const elementos = [
              "id_processo_etapa",
              "id_processo_etapa_status",
              "data_criacao_inicio",
              "data_criacao_fim",
              "data_conclusao_inicio",
              "data_conclusao_fim"
            ];
            elementos.forEach(id => {
              const elemento = document.getElementById(id);
              if (elemento) {
                elemento.value = '';
              }
            });
            if ([1, 4, 5, 6, 7, 8].includes(parseInt(user.tipo))) {
              const id_operadora = document.getElementById("id_operadora");
              const id_usuario = document.getElementById("id_usuario");
              if (id_operadora) id_operadora.value = '';
              if (id_usuario) id_usuario.value = '';
            }
            if ([3].includes(parseInt(user.tipo))) {
              const id_usuario = document.getElementById("id_usuario");
              if (id_usuario) id_usuario.value = '';
            } */
        setFiltrosProcessos({});
        setFiltrosProcessos(false);
        window.location.reload();
        //getSearch(null, '', true);
    }

    useEffect(() => {
        getSearch(1, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtrosProcessos]);

    useEffect(() => {
        (async () => {
            await getSearch();
            if (config.user.tipo === '2' || config.user.tipo === '3') {
                getCombosOPS()
            }
            else {
                getCombos()
            };
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    return (
        <Layout className="view-listagem">
            <p className="page-title">Relatórios</p>
            <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
                Financeiro
            </h2>
            <div className="flex jc-sb ai-fc mb-60">
                <div className="container-search-filter flex ai-fc jc-sb gap-25">
                </div>
                <div className="flex ai-fc gap-30 desktop">
                    <button className="btn btn-primary f700-16" onClick={exportToPdf} disabled={loaderPdf || loaderCsv}>
                        {loaderPdf ? <Loading /> : <i className="icon icon-print i-25" />} <span className="pl-5">Imprimir</span>
                    </button>
                    <button className="btn btn-primary f700-16" onClick={exportToCsv} disabled={loaderCsv || loaderPdf}>
                        {loaderCsv ? <Loading /> : <i className="icon icon-document i-25" />}<span className="pl-5">Exportar</span>
                    </button>
                </div>
            </div>
            <div className="flex ai-fc jc-sb mb-30">
                <div className="flex ai-fc gap-25">
                    <div className="col-3">
                        <FormControl className='span-4' sx={{ display: "flex", alignSelf: "end", padding: 0, margin: 0 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Colunas Visíveis</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedColumns}
                                onChange={handleChange}
                                input={<OutlinedInput label="Colunas Visíveis" />}
                                renderValue={(selected) => {
                                    const selectedLabels = visibleColumns
                                        .filter(column => selected.includes(column.id))
                                        .map(column => column.label);
                                    return selectedLabels.join(', ');
                                }}
                            >
                                {visibleColumns.map((column) => (
                                    <MenuItem key={column.id} value={column.id}>
                                        <Checkbox checked={selectedColumns.includes(column.id)} />
                                        <ListItemText primary={column.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Operadora"
                            type="search"
                            className="span-6"
                            placeholder="Pesquisar..."
                            onSearch={searchOperadora}
                            name="id_operadora"
                            model={(...params) => {
                                const idOperadora = params[0]?.id || '';
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_operadora: idOperadora
                                }));
                            }}
                        />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Profissional desempatador"
                            type="search"
                            placeholder="Pesquisar..."
                            className="span-3"
                            name="profissional_desempatador"
                            onSearch={searchDesempatadores}
                            model={(...params) => {
                                const idDesempatador = params[0]?.id;
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_desempatador: idDesempatador
                                }));
                            }}
                        />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="CRM/CRO"
                            type="search"
                            placeholder="Pesquisar..."
                            className="span-6"
                            name="crm_and_cro"
                            onSearch={searchCrmCro}
                            model={(...params) => {
                                const idCrmCro = params[0]?.id || '';
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    crm_and_cro: idCrmCro
                                }));
                            }}
                        />
                    </div>
                    <div className="col-2">
                        <InputForm
                            label="Status"
                            className="span-3"
                            name="id_status"
                            type="select"
                            value={filtrosProcessos.id_status}
                            options={options?.status}
                            model={setModel} />
                    </div>
                </div>
            </div>
            <div className="flex ai-fc jc-sb mb-60">
                <div className="flex ai-fc gap-25">
                    <div className="col-4">
                        <InputForm
                            label="Tipo de análise"
                            required
                            type="select"
                            className="gap-25"
                            name="id_type_analise"
                            options={typeAnalise}
                            value={filtrosProcessos?.id_processo_tipo_analise}
                            model={(name, id) => {
                                const selectedAnalise = typeAnalise.find(analise => analise.id === Number(id));
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_processo_tipo_analise: selectedAnalise?.id || ''
                                }));
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <InputForm
                            label="Tipo de processo"
                            required
                            type="select"
                            className="gap-25"
                            name="id_type_user"
                            options={typeProcess}
                            value={filtrosProcessos?.typeProcess}
                            model={(name, id) => {
                                const selectedProcess = typeProcess.find(process => process.id === Number(id));
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    typeProcess: selectedProcess?.id || ''
                                }));
                            }}
                        />
                    </div>
                    <div className="col-4">
                        <InputForm
                            label="Tipo de conclusão"
                            required
                            type="select"
                            className="gap-25"
                            name="id_type_analise"
                            options={typeConclusao}
                            value={filtrosProcessos?.id_processo_conclusao}
                            model={(name, id) => {
                                const selectedConclusao = typeConclusao.find(conclusao => conclusao.id === Number(id));
                                setFiltrosProcessos(prevState => ({
                                    ...prevState,
                                    id_processo_conclusao: selectedConclusao?.id || ''
                                }));
                            }}
                        />
                    </div>
                    <span className="pointer float-right ml-20 mt-30" onClick={limparFiltros}>
                        <i className="icon icon-trash watermelon i-25" title="Limpar filtros" />
                    </span>
                </div>
            </div>
            {loading ? <div className="loader loader-default is-active" /> : (<div style={{ overflowX: 'scroll' }}>
                <div className="table-mobile">
                    {processos.map((p, i) => {
                        return <div className="item pointer" key={`mobile-${i}`}>
                            <div>
                                <div className="fn-16 linha flex jc-sb">
                                    Protocolo <span>{p.id_protocolo}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Data de conclusão <span>{p.data_conclusao ? tools.formatDate(p.data_conclusao) : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Tipo de processo <span>{p.desc_processo_tipo}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Operadora <span>{p.desc_operadora}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Plano <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Guia <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Beneficiário <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Beneficiário CPF <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Carteirinha <span>{p.plano ? p.plano : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Especialista <span>{p.nome_desempatador ? p.nome_desempatador : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    CRM/CRO <span>{p.crm_cro ? p.crm_cro : '-'}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Status <span>{p.desc_processo_etapa_status}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Conclusão <span>{p.processo_conclusao}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">
                                    Tipo análise <span>{p.tipo_analise}</span>
                                </div>
                                <div className="fn-16 linha flex jc-sb">{p.valor_operadora ? tools.formatCurrency(p.valor_operadora) : '-'}  </div>

                                <div className="fn-16 linha flex jc-sb">{p.valor_desempatador ? tools.formatCurrency(p.valor_desempatador) : '-'}  </div>

                                <div className="fn-16 linha flex jc-sb">
                                    Lucro Bruto <span>{p.valor_operadora - p.valor_desempatador}</span>
                                </div>
                            </div>
                            <div className="w-100 flex jc-fc">
                                -
                            </div>
                        </div>
                    })}
                    {(!processos || !processos?.length) && <div className="item pointer" key={`mobile-0`}>
                        Nenhum resultado encontrado.
                    </div>}
                </div>
                <table className="w-100 table-desktop" style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead className="f300-16">
                        <tr>
                            {visibleColumns.map(column => column.visible && (
                                <th key={column.id} className="text-left bold" style={{ padding: '4px', border: '1px solid #ddd', backgroundColor: '#f2f2f2' }}>{column.label}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {processos.map((p, i) => (
                            <tr key={`desktop-${i}`} className="hover-highlight">
                                {visibleColumns.map(column => column.visible && (
                                    <td key={column.id} className="fn-16 text-left" style={{ padding: '4px', border: '1px solid #ddd' }}>
                                        {formatValue(column.id, p)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {(!processos || !processos.length) && <tr>
                            <td style={{ textAlign: 'center', padding: '8px' }} colSpan={visibleColumns.filter(col => col.visible).length}>Nenhum resultado encontrado.</td>
                        </tr>}
                    </tbody>
                </table>
                <div className="component pagination">
                    <div className="pagination-container mt-30">
                        {pager.total_pages < 2 ? '' : <ReactPaginate
                            previousLabel="«"
                            nextLabel="»"
                            breakLabel="..."
                            breakClassName="break-me"
                            page={pager.page - 1 ?? 0}
                            forcePage={pager.page - 1 ?? 0}
                            pageCount={pager.total_pages}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            subContainerClassName="pages pagination"
                            activeClassName="active" />}
                    </div>
                </div>
            </div>)
            }

        </Layout >
    );
}