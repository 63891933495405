import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import InputForm from "../Form/InputForm.js";
import Loading from '../Loading.js';
import Modal from '../Modal';
import ToggleSwitch from '../ToggleSwitch.js';
import business from '../../services/business.js';
import tools from '../../lib/tools.js';
import { useCallback } from 'react';
import config from '../../config/config.js';
import ModalReenvioComunicado from './ModalReenvioComunicado.js';

export default function ModalProcesso({ hideModal, avancarEtapa, show, etapa = {}, processo = {}, salvandoDados = false }) {
  var statusPermitidos = [];
  var bloqueioAvanco = false;

  //analise desempatador
  if (parseInt(processo.id_processo_etapa) === 3 && [6].includes(parseInt(config.user.tipo))) {
    statusPermitidos = Array.isArray(etapa?.status_permitidos)
      && etapa.status_permitidos.filter(
        (status) => status.id !== 3 && status.id !== 4 && status.id !== 41
      );
  }
  //ciência conclusão
  else if (parseInt(processo.id_processo_etapa) === 11 && [4, 8].includes(parseInt(config.user.tipo))) {
    statusPermitidos = Array.isArray(etapa?.status_permitidos)
      && etapa.status_permitidos.filter(
        (status) => status.id !== 31108
      );
  }
  //auditoria
  else if (parseInt(processo.id_processo_etapa) === 316 && [4].includes(parseInt(config.user.tipo))) {
    statusPermitidos = Array.isArray(etapa?.status_permitidos)
      && etapa.status_permitidos.filter(
        (status) => status.id !== 50 && status.id !== 51 && status.id !== 31605
      );
  }
  //auditoria pós
  else if (parseInt(processo.id_processo_etapa) === 317 && [4].includes(parseInt(config.user.tipo))) {
    statusPermitidos = Array.isArray(etapa?.status_permitidos)
      && etapa.status_permitidos.filter(
        (status) => status.id !== 31702 && status.id !== 31703 && status.id !== 31705
      );
  }
  else {
    statusPermitidos = etapa.status_permitidos
  }

  //bloqueio de avanço em auditorias
  if ([50, 51, 31605, 31702, 31703, 31705].includes(parseInt(processo.id_processo_etapa_status)) && [4].includes(parseInt(config.user.tipo))) {
    bloqueioAvanco = true;
  }

  const [form, setForm] = useState({
    status: processo?.id_processo_etapa_status || 0,
    comentario: ''
  });

  const [error, setError] = useState({});

  const permiteAvancarEtapa = (etapa?.status_permitidos && etapa?.status_permitidos?.length === 1);

  const setModel = (name, value) => {
    setForm((prevState) => ({ ...prevState, ...{ [name]: value } }));
  }

  const setSearch = (name, data) => {
    setForm((prevState) => ({
      ...prevState, ...{
        [`id_${name}`]: data.id,
        [`nome_${name}`]: data.nome,
      }
    }));
  }

  const setModelToggle = (name, value) => {
    desempatadorRef.current.clearSearch();
    setForm((prevState) => ({ ...prevState, ...{ id_desempatador: null, nome_desempatador: '' } }));
    setError((prevError) => ({ ...prevError, id_desempatador: null }));
    setModel(name, value);
  }

  const searchDesempatador = async (e) => {
    return await business.buscarDesempatador(processo.id, e.target.value);
  }

  const searchListRender = (item) => {
    return <div className="flex" key={`custom-${tools.uuidv4()}`}>
      <div className="col-8 f400-16 lines-3">{item.nome}</div>
      <div className="col-4 f400-12 color-gray text-right pr-10">
        {item.tipo_conselho} {item.crm}/{item.crm_uf}
      </div>
    </div>
  }

  const validate = useCallback(() => {
    if (etapa && etapa?.id && [9].includes(parseInt(etapa.id)) && [16, 35, 49, 10948].includes(parseInt(form.status)) && !form?.id_desempatador && !form?.auto_escolher_desempatador) {
      setError((prevState) => ({ ...prevState, id_desempatador: 'Selecione um profissional para continuar' }))
    } else if (form?.id_desempatador || form?.auto_escolher_desempatador === true) {
      setError({});
    }
  }, [etapa, form]);

  const [showModalReenvioComunicado, setShowModalReenvioComunicado] = useState(false);

  const openModal = async (event = {}) => {
    event?.preventDefault();
    setShowModalReenvioComunicado(true);
  };

  const hideModalReenvioComunicado = async (event) => {
    event?.preventDefault();
    setShowModalReenvioComunicado(false);
    const body = document.body;
    body.classList.remove("overflow-hidden");
  };

  useEffect(() => {
    setForm({ ...form, ...{ status: processo?.id_processo_etapa_status } });
    // eslint-disable-next-line
  }, [processo?.id_processo_etapa_status]);

  useEffect(() => {
    validate();
  }, [form, validate]);

  const desempatadorRef = useRef();

  return (
    <Modal show={show} handleClose={hideModal || (() => { })} style={{ maxWidth: '600px', maxHeight: '800px' }} modalClass="modal-cad-usuario">
      {showModalReenvioComunicado && (
        <ModalReenvioComunicado
          show={showModalReenvioComunicado}
          hideModal={hideModalReenvioComunicado}
          avancarEtapa={() => avancarEtapa(form, setError, setForm)}
        />
      )}
      <div className="flex">
        {bloqueioAvanco ?
          (<div className="form w-100 mb-50">
            <h1 className="title">Etapa: {etapa.nome}</h1>
            <div className="group-input f400-18 mt-50">
              <p>Você está na etapa de <b>{etapa.nome}</b> e seu status atual é <b>{etapa.descricao}</b>.<br /><br />Aguarde até que possa interagir.</p>
            </div>
            <div className="form-rodape mt-30">
              <button className="btn btn-outline-primary f700-16" onClick={hideModal} >
                Cancelar
              </button>
            </div>
          </div>)
          : (<div className="form w-100 mb-50">
            <h1 className="title">Etapa: {etapa.nome}</h1>
            <div className="group-input f400-18 mt-50">
              <p>Você está na etapa de <b>{etapa.nome}</b> e seu status atual é <b>{etapa.descricao}</b>.<br />Deseja {permiteAvancarEtapa ? 'avançar de' : 'mudar o status desta'} etapa?</p>
            </div>
            <div className="group-input f400-18 mt-50">
              <InputForm label={"Status"} type="select" error={error.status} options={statusPermitidos || []} value={form.status} disabled={permiteAvancarEtapa} name="status" model={setModel} />
            </div>
            {(etapa && etapa?.id && [9].includes(parseInt(etapa.id)) && [16, 35, 49, 10948].includes(parseInt(form.status))) && <>
              <div className="group-input f400-18 mt-30">
                <InputForm label={`${processo.id_processo_tipo === '3' ? 'Desempatador' : 'Especialista'}`} ref={desempatadorRef} type="search" searchListRender={searchListRender} className="span-4" onSearch={searchDesempatador} name="id_desempatador" model={(...params) => setSearch('desempatador', ...params)}
                  value={form.nome_desempatador} error={error.id_desempatador} disabled={form?.auto_escolher_desempatador} placeholder={(form?.auto_escolher_desempatador) ? 'Escolha automática' : 'Escolha um profissional..'} />
              </div>
              {processo.id_processo_tipo === '3' && <div className="group-input f400-16 mt-30 flex">
                <span className="col-10 lh-30">Escolher automaticamente o <b>Desempatador</b>?</span>
                <span className="col-2">
                  <ToggleSwitch name="auto_escolher_desempatador" value={form.auto_escolher_desempatador || 0}
                    texto={['Sim', 'Não']}
                    model={setModelToggle} />
                </span>
              </div>}
            </>}
            {(etapa && etapa?.id && ([10].includes(parseInt(etapa.id)) || [3, 4, 41].includes(parseInt(form.status)))) && <div className="group-input f400-18 mt-30">
              <InputForm require={form.status === 3 || form.status === 4 ? 'required' : false} label="Comentário" type="textarea" height="100px" name="comentario" value={form.comentario} model={setModel} />
            </div>}
            <div className="form-rodape mt-30">
              <button className="btn btn-outline-primary f700-16" onClick={hideModal} >
                Cancelar
              </button>
              <button
                className="btn btn-primary f700-16"
                disabled={salvandoDados || Object.keys(error).length}
                onClick={(e) => (parseInt(form.status) === 47 && parseInt(processo.id_processo_etapa) === 6 && parseInt(processo.envio_convites) === 1) || (parseInt(form.status) === 14 && parseInt(processo.id_processo_etapa) === 8 && parseInt(processo.envio_abertura) === 1) || (parseInt(form.status) === 14 && parseInt(processo.id_processo_etapa === 11 && parseInt(processo.envio_abertura) === 1)) ? openModal(e, form, setError, setForm) : avancarEtapa(form, setError, setForm)}>
                {salvandoDados ? <Loading /> : (permiteAvancarEtapa ? 'Avançar etapa' : 'Salvar')}
              </button>
            </div>
          </div>)}
      </div>
    </Modal >
  );
}