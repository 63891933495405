import Cep from "cep-promise";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import tools from "../../lib/tools.js";
import business from "../../services/business.js";
import InputForm from "../Form/InputForm.js";
import Loading from "../Loading.js";
import Modal from "../Modal";

export default function ModalUsuario({ hideModal, show, dto = {} }) {
  const [validacao, setValidacao] = useState({});
  const [form, setForm] = useState(dto);
  const [options, setOptions] = useState({});
  const [permitirCadastro, setPermitirCadastro] = useState(false);
  const [stage, setStage] = useState("config");
  const [etapas, setEtapas] = useState([
    { id: "config", nome: "Configuração" },
  ]);
  const [loaded, setLoaded] = useState(false);
  const [salvandoDados, setSalvandoDados] = useState(false);
  const alert = useAlert();

  const getCombos = async () => {
    if (!options?.estados) {
      let roles = [],
        areaAtuacao = [],
        especialidade = [],
        estados = business.getEstados();
      await business.getRoles().then((l) => (roles = l));
      await business.getEspecialidade().then((l) => (especialidade = l));
      setOptions({
        ...{ roles, estados, especialidade, areaAtuacao },
        ...options,
      });
    }
  };

  const getUsuario = async () => {
    if (dto?.id && !loaded) {
      let promises = [],
        usuario = {};
      promises.push(business.getUsuario(dto?.id).then((l) => (usuario = l)));
      await Promise.all(promises);
      if (usuario && usuario?.id) {
        usuario.especialidade =
          usuario?.especialidade.map((option) => {
            return {
              id: option.id,
              value: option.id,
              label: option.desc,
              nome: option.desc,
            };
          }) || [];
        usuario.area_atuacao =
          usuario?.area_atuacao.map((option) => {
            return {
              id: option.id,
              value: option.id,
              label: option.desc,
              nome: option.desc,
            };
          }) || [];
        setForm(usuario);
        setLoaded(true);
      }
    }
  };

  const checkIsValidate = async () => {
    let validated = true;
    let validacao = {};

    const obrigatorios = [];

    document
      .querySelectorAll(".dados-usuario [name][required]")
      .forEach((e) => {
        if (e.name) obrigatorios.push(e.name);
      });

    let id_usuarios_tipo = parseInt(form.id_usuarios_tipo);

    // médico desempatador
    if ([6].includes(id_usuarios_tipo)) {
      setEtapas([
        { id: "config", nome: "Configuração" },
        { id: "dados", nome: "Dados do usuário" },
        { id: "endereco", nome: "Endereço" },
        { id: "perfil", nome: "Perfil" },
        { id: "area_atuacao", nome: "Área de atuação" },
        { id: "valores", nome: "Valores" },
        { id: "seguranca", nome: "Segurança" },
      ]);
    } else if (id_usuarios_tipo > 0) {
      setEtapas([
        { id: "config", nome: "Configuração" },
        { id: "dados", nome: "Dados do usuário" },
        { id: "seguranca", nome: "Segurança" },
      ]);
    }

    obrigatorios.forEach((campo) => {
      if (
        typeof form[campo] === "undefined" ||
        tools.isEmptyNullOrUndefined(form[campo])
      ) {
        validacao[campo] = "Campo Obrigatório";
        validated = false;
      } else if (["cpf"].includes(campo)) {
        const { valido } = tools.validarCPF(form[campo]);

        if (!valido) {
          validacao[campo] = "Digite um CPF válido.";
          validated = false;
        }
      } else if (["nome"].includes(campo)) {
        const valido = form[campo].length > 2;

        if (!valido) {
          validacao[campo] = "Digite um nome válido";
          validated = false;
        }
      } else if (["email"].includes(campo)) {
        const valido = tools.validarEmail(form[campo]);

        if (!valido) {
          validacao[campo] = "Digite um e-mail válido";
          validated = false;
        }
      } else if (["telefone", "telefone2"].includes(campo)) {
        const valido = tools.validarTelefone(form[campo]);

        if (!valido) {
          validacao[campo] = "Digite um telefone válido";
          validated = false;
        }
      } else if (["senha"].includes(campo)) {
        const { valido, texto } = tools.validarSenha(form[campo], 8);

        if (!valido) {
          validacao[campo] = texto;
          validated = false;
        }
      } else if (
        ["confirmacao_senha"].includes(campo) &&
        !tools.isEmptyNullOrUndefined(form[campo])
      ) {
        const valido = form[campo] === form["senha"];

        if (!valido) {
          validacao[campo] = "A senhas não são iguais";
          validated = false;
        }
      }
    });

    validated = validated && id_usuarios_tipo;

    setValidacao(validacao);
    setPermitirCadastro(validated);
  };

  const setModel = (name, value) => {
    setForm({ ...form, ...{ [name]: value } });
  };

  const getAreaAtuacao = async (value) => {
    setOptions({ ...{ areaAtuacao: [] }, ...options });
    let id_especialidade =
      form?.especialidade?.map((e) => e.id).join(",") || false;
    let response = await business.getAreaAtuacao(value, 5, 1, id_especialidade);
    setOptions({ ...options, ...{ areaAtuacao: response } });
    return response;
  };

  const getAreaAtuacaoAync = async (value) => {
    setOptions({ ...options, ...{ areaAtuacao: [] } });
    let isRemoved =
      form?.especialidade?.filter((x) => !value.includes(x)).length > 0;
    if (isRemoved) {
      form.especialidade = value;
      form.area_atuacao = [];
      setForm(form);
    }
    let id_especialidade = value?.map((e) => e.id).join(",") || false;
    if (id_especialidade) {
      let response = await business.getAreaAtuacao("", 5, 1, id_especialidade);
      setOptions({ ...options, ...{ areaAtuacao: response } });
    }
  };

  const getespecialidade = async (value) => {
    let response = await business.getEspecialidade(value);
    setOptions({ ...options, ...{ especialidade: response } });
    return response;
  };

  const searchOperadora = async (e) => {
    return await business.getOperadora(e.target.value);
  };

  const setSearch = (name, data) => {
    setForm({
      ...form,
      ...{
        [`id_${name}`]: data.id,
        [`nome_${name}`]: data.nome,
      },
    });
  };

  const getCep = async (e) => {
    if (!form.cep) return;
    Cep(form.cep).then((cep) => {
      setForm({
        ...form,
        ...{
          cidade: cep.city,
          logradouro: cep.street,
          bairro: cep.neighborhood,
          uf: cep.state,
          cep: cep.cep,
        },
      });
      let input = document.getElementsByName("numero");
      if (input.length) input[0].focus();
    });
  };

  const salvar = async (e) => {
    checkIsValidate();

    let formData = Object.assign({}, form);

    if (formData?.especialidade) {
      formData.especialidade = formData.especialidade.map((item) => {
        return { id: item.id, desc: item.label };
      });
    }

    if (formData?.area_atuacao) {
      formData.area_atuacao = formData.area_atuacao.map((item) => {
        return { id: item.id, desc: item.label };
      });
    }

    if (formData?.confirmacao_senha) {
      delete formData.confirmacao_senha;
    }

    if (permitirCadastro) {
      document.body.classList.add("overflow-hidden");
      setSalvandoDados(true);
      const { errors } = await business.usuario(formData, form?.id || false);
      if (errors.length) {
        (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach((e) =>
          alert.error(e)
        );
        setSalvandoDados(false);
      } else {
        alert.success(
          form?.id ? "As informações foram salvas" : "O usuário foi criado"
        );
        hideModal(e, true);
        document.body.classList.remove("overflow-hidden");
        setSalvandoDados(false);
      }
    }
  };

  useEffect(() => {
    checkIsValidate();
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    getCombos();
    getUsuario();
    // eslint-disable-next-line
  }, [dto]);

  return (
    <Modal
      show={show}
      handleClose={hideModal}
      style={{ maxWidth: "1200px", maxHeight: "95%" }}
      modalClass="modal-cad-usuario"
    >
      <form
        autoComplete="off"
        onSubmit={() => {
          return false;
        }}
      >
        <div className="flex">
          <div className="options flex fd-fc jc-sb">
            <div className="ml-10">
              <h1 className="title mb-45">
                {form?.id ? "Editar usuário" : "Novo usuário"}
              </h1>
              <ul
                className={`f600-18 p-0 flex fd-fc stage gap-15 ${options && options?.roles ? "" : "disabled"
                  }`}
              >
                {etapas.map((etapa) => {
                  return (
                    <li
                      key={etapa.id}
                      className={etapa.id === stage ? "active" : ""}
                      onClick={() => {
                        if (options && options?.roles) {
                          setStage(etapa.id);
                        }
                      }}
                    >
                      {etapa.nome}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary w-100 fb-16"
                disabled={!options?.roles || !permitirCadastro || salvandoDados}
                onClick={salvar}
              >
                {salvandoDados ? (
                  <Loading />
                ) : form?.id ? (
                  "Salvar"
                ) : (
                  "Criar Usuário"
                )}
              </button>
            </div>
          </div>
          <div className="form w-100">
            <div className="dados-usuario">
              <div className={`etapa ${stage === "config" && "ativa"}`}>
                <h1 className="subtitle mb-45">Configuração</h1>
                {options && options?.roles ? (
                  <div className="form-container grid col-12">
                    <InputForm
                      label="Tipo de usuário"
                      type="select"
                      options={options?.roles || []}
                      className="span-6"
                      value={form?.id_usuarios_tipo}
                      name="id_usuarios_tipo"
                      model={setModel}
                      error={validacao.id_usuarios_tipo}
                      required
                    />
                    {["2", "3"].includes(form?.id_usuarios_tipo) && (
                      <InputForm
                        label="Operadora"
                        type="search"
                        className="span-6"
                        onSearch={searchOperadora}
                        name="id_operadora"
                        model={(...params) => setSearch("operadora", ...params)}
                        value={form.desc_operadora}
                        error={validacao.id_operadora}
                        required
                      />
                    )}
                  </div>
                ) : (
                  <Loading />
                )}
                {!form?.id_usuarios_tipo && (
                  <div
                    className="f600-28 flex ai-fc jc-fc text-center mt-60"
                    style={{ color: "#DFDFDF", height: "70%" }}
                  >
                    Selecione o tipo de usuário
                  </div>
                )}
              </div>
              <div className={`etapa ${stage === "dados" && "ativa"}`}>
                <h1 className="subtitle mb-45">Dados do usuário</h1>
                <div className="form-container grid col-12">
                  <InputForm
                    label="CPF"
                    className="span-6"
                    mask="###.###.###-##"
                    value={form.cpf}
                    name="cpf"
                    model={setModel}
                    validate="cpf"
                    error={validacao.cpf}
                    required
                  />
                  <InputForm
                    label="Nome"
                    className="span-6"
                    value={form.nome}
                    name="nome"
                    model={setModel}
                    error={validacao.nome}
                    required
                  />
                  <InputForm
                    label="E-mail"
                    className="span-12"
                    type="email"
                    value={form.email}
                    name="email"
                    model={setModel}
                    error={validacao.email}
                    required
                  />
                  <InputForm
                    label="Telefone 1"
                    className="span-6"
                    mask={"(##) #####-####"}
                    value={form.telefone}
                    name="telefone"
                    model={setModel}
                    error={validacao.telefone}
                    required
                  />
                  <InputForm
                    label="Telefone 2"
                    className="span-6"
                    mask={"(##) #####-####"}
                    value={form.telefone2}
                    name="telefone2"
                    model={setModel}
                    error={validacao.telefone2}
                  />

                  {["6"].includes(form?.id_usuarios_tipo) && (
                    <InputForm
                      label="CRM"
                      className="span-4"
                      type="text"
                      value={form.crm}
                      name="crm"
                      model={setModel}
                    />
                  )}

                  {["6"].includes(form?.id_usuarios_tipo) && (
                    <InputForm
                      label="Estado"
                      className="span-2"
                      type="select"
                      placeholder={"UF"}
                      value={form.crm_uf}
                      name="crm_uf"
                      error={validacao.crm_uf}
                      {...(form?.crm ? { required: true } : {})}
                      model={setModel}
                      options={(options?.estados || []).map((item) => {
                        return { id: item.id, nome: item.id };
                      })}
                    />
                  )}

                  {["6"].includes(form?.id_usuarios_tipo) && (
                    <InputForm
                      label="CRO"
                      className="span-4"
                      type="text"
                      value={form.cro}
                      name="cro"
                      model={setModel}
                    />
                  )}

                  {["6"].includes(form?.id_usuarios_tipo) && (
                    <InputForm
                      label="Estado"
                      className="span-2"
                      type="select"
                      placeholder={"UF"}
                      value={form.cro_uf}
                      name="cro_uf"
                      error={validacao.cro_uf}
                      {...(form?.cro ? { required: true } : {})}
                      model={setModel}
                      options={(options?.estados || []).map((item) => {
                        return { id: item.id, nome: item.id };
                      })}
                    />
                  )}

                  <InputForm
                    label="Observações"
                    type="textarea"
                    height={200}
                    maxLength={512}
                    className="span-12"
                    value={form?.observacao || ""}
                    name="observacao"
                    model={setModel}
                  />
                </div>
              </div>

              <div className={`etapa ${stage === "endereco" && "ativa"}`}>
                <h1 className="subtitle mb-45">Endereço</h1>
                <div className="form-container grid col-12">
                  <InputForm
                    label="CEP"
                    mask={"#####-###"}
                    className="span-4"
                    value={form.cep}
                    onBlur={getCep}
                    name="cep"
                    model={setModel}
                  >
                    <i
                      className="icon icon-bolt blue i-15"
                      style={{
                        position: "absolute",
                        right: "18px",
                        top: "17px",
                      }}
                    />
                  </InputForm>
                  <InputForm
                    label="Logradouro"
                    className="span-8"
                    value={form.logradouro}
                    name="logradouro"
                    model={setModel}
                  />

                  <InputForm
                    label="Número"
                    className="span-4"
                    value={form.numero}
                    name="numero"
                    model={setModel}
                  />
                  <InputForm
                    label="Complemento"
                    className="span-8"
                    value={form.complemento}
                    name="complemento"
                    model={setModel}
                  />

                  <InputForm
                    label="Bairro"
                    className="span-4"
                    value={form.bairro}
                    name="bairro"
                    model={setModel}
                  />
                  <InputForm
                    label="Cidade"
                    className="span-4"
                    value={form.cidade}
                    name="cidade"
                    model={setModel}
                  />
                  <InputForm
                    label="Estado"
                    className="span-4"
                    type="select"
                    placeholder={"Selecione"}
                    value={form.uf}
                    name="uf"
                    model={setModel}
                    options={options.estados}
                  />
                </div>
              </div>

              <div className={`etapa ${stage === "perfil" && "ativa"}`}>
                <h1 className="subtitle mb-45">Perfil</h1>
                <div className="form-container col-12">
                  <InputForm
                    label="Descrição"
                    type="textarea"
                    height={200}
                    maxLength={1024}
                    className="span-12"
                    value={form.descricao}
                    name="descricao"
                    model={setModel}
                  />
                </div>
              </div>

              <div className={`etapa ${stage === "area_atuacao" && "ativa"}`}>
                <h1 className="subtitle mb-45">Área de atuação</h1>
                <div className="form-container grid col-12">
                  {["6"].includes(form?.id_usuarios_tipo) && (
                    <InputForm
                      label="Especialidades"
                      className="span-12"
                      required
                      error={validacao.especialidade}
                      type="select-search-async"
                      optionsAsync={getespecialidade}
                      defaultOptions={options.especialidade}
                      value={form.especialidade}
                      name="especialidade"
                      model={setModel}
                      placeholder="Digite"
                      onSelect={(name, value) => getAreaAtuacaoAync(value)}
                    />
                  )}
                  {["6"].includes(form?.id_usuarios_tipo) && (
                    <InputForm
                      label="Áreas de atuação"
                      className="span-12"
                      required
                      error={validacao.area_atuacao}
                      type="select-search-async"
                      optionsAsync={getAreaAtuacao}
                      options={
                        !form?.especialidade?.length ? options.areaAtuacao : []
                      }
                      defaultOptions={options.areaAtuacao}
                      value={form.area_atuacao}
                      name="area_atuacao"
                      model={setModel}
                      placeholder={
                        !form?.especialidade?.length
                          ? "Selecione as especialidade"
                          : "Digite"
                      }
                      disabled={!form?.especialidade?.length}
                    />
                  )}
                </div>
              </div>

              <div className={`etapa ${stage === "valores" && "ativa"}`}>
                <h1 className="subtitle mb-45">Valores</h1>
                <div className="grid md-gap">
                  <div className="col-12 flex">
                    <InputForm
                      label="Consenso Pré"
                      type="decimal"
                      className="span-3"
                      value={form.valor_cp}
                      name="valor_cp"
                      model={setModel}
                      validate="valor_cp"
                      error={validacao.valor_cp}
                    />
                  </div>
                  <div className="col-12 flex">
                    <InputForm
                      label="Segunda Opinião"
                      type="decimal"
                      className="span-3"
                      value={form.valor_so}
                      name="valor_so"
                      model={setModel}
                      validate="valor_so"
                      error={validacao.valor_so}
                    />
                  </div>
                  <div className="col-12 flex md-gap">
                    <InputForm
                      label="JMO (Documental)"
                      type="decimal"
                      className="span-3"
                      value={form.valor_jmo_doc}
                      name="valor_jmo_doc"
                      model={setModel}
                      validate="valor_jmo_doc"
                      error={validacao.valor_jmo_doc}
                    />
                    <InputForm
                      label="JMO (Vídeoconferência)"
                      type="decimal"
                      className="span-3"
                      value={form.valor_jmo_video}
                      name="valor_jmo_video"
                      model={setModel}
                      validate="valor_jmo_video"
                      error={validacao.valor_jmo_video}
                    />
                  </div>
                </div>
              </div>

              <div className={`etapa ${stage === "seguranca" && "ativa"}`}>
                <h1 className="subtitle mb-45">Segurança</h1>
                <div className="form-container grid col-12">
                  {
                    <InputForm
                      label="Senha"
                      type="password"
                      className="span-6"
                      value={form.senha}
                      name="senha"
                      model={setModel}
                      error={validacao.senha}
                      {...(!form?.id || form.senha ? { required: true } : {})}
                    />
                  }
                  {
                    <InputForm
                      label="Confirmação de senha"
                      type="password"
                      className="span-6"
                      value={form.confirmacao_senha}
                      name="confirmacao_senha"
                      model={setModel}
                      error={validacao.confirmacao_senha}
                      {...(form?.senha ? { required: true } : {})}
                    />
                  }
                </div>
                <br />
                <br />
                <p className="fn-13">
                  Sua senha deve conter mais de 8 caracteres e combinar letras
                  maiúsculas, minúsculas, números e caracteres especiais.
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
