import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Layout from "../../components/Layout.js";
import MenuDots from "../../components/MenuDots.js";
import ModalOperadora from "../../components/Modais/ModalOperadora.js";
import Search from "../../components/Form/Search.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import "../../assets/styles/pages/Operadoras.scss";
import "pure-css-loader/src/loader-default.sass";
import business from "../../services/business.js";
import layout from "../../lib/layout.js";
import config from "../../config/config.js";

export default function Operadoras() {
  document.title = "säästo";
  layout.openMenu("/operadoras", true);
  const [pager, setPager] = useState({
    page: 1,
    total_pages: 0,
    total_rows: 0,
  });

  const [listagem, setListagem] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [operadora, setOperadora] = useState({});

  const openModal = async (event = {}, row = {}) => {
    event?.preventDefault();
    if (row?.id) setOperadora(row);
    setShowModal(true);
  };

  const hideModal = async (event, reload = false) => {
    event?.preventDefault();
    setShowModal(false);
    setOperadora({});
    if (reload) {
      await getSearch();
    }
  };

  const menuItens = [
    {
      label: "Editar",
      icon: "icon-edit blue i-25",
      className: "menu-icon",
      onClick: openModal,
      disabled: !config.temPermissao("admin:operadoras:editar"),
    },
  ];

  const formatPhone = (value) => {
    if (!value) return value;
    const phonePattern = /^(\d{0,2})(\d{0,5})(\d{0,4})$/;
    const matches = value.replace(/\D/g, "").match(phonePattern);
    const part1 = matches[1] ? `(${matches[1]}) ` : "";
    const part2 = matches[2] ? `${matches[2]}` : "";
    const part3 = matches[3] ? `-${matches[3]}` : "";
    return `${part1}${part2}${part3}`.trim();
  };

  const formatCNPJ = (value) => {
    if (!value) return value;
    const cnpjPattern = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    const matches = value.replace(/\D/g, "").match(cnpjPattern);
    if (!matches) return value;
    return `${matches[1]}.${matches[2]}.${matches[3]}/${matches[4]}-${matches[5]}`;
  };

  const handlePageClick = (e) => {
    getSearch(e.selected + 1);
  };

  const getSearch = async (page = null, value = "", loading = true) => {
    setLoading(loading);
    let operadoras = await business.getAllOperadora(
      page === null ? pager.page : page,
      10,
      value
    );
    setListagem(operadoras?.response || []);
    if (
      operadoras?.response &&
      pager.page &&
      operadoras?.pager?.paginas > 0 &&
      operadoras?.pager?.paginas < pager.page
    ) {
      pager.page = 1;
      return getSearch();
    }
    pager.page = operadoras?.pager?.pagina;
    setPager({
      page: operadoras?.pager?.pagina,
      total_pages: operadoras?.pager?.paginas,
      total_rows: operadoras?.pager?.total,
    });
    setLoading(false);
    setSearchLoading(false);
  };

  const handleWindowSizeChange = (mobileSize) => {
    let checkIsMobile = window.innerWidth < mobileSize;
    setIsMobile(checkIsMobile);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      return handleWindowSizeChange(1180);
    });
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await getSearch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="view-listagem">
      {showModal && (
        <ModalOperadora
          show={showModal}
          hideModal={hideModal}
          dto={operadora}
        />
      )}
      <p className="page-title">
        Configurações / <span className="blue-text">Operadoras</span>
      </p>
      <h2 className="title flex jc-sb ai-fe black-text mb-40 gap-10">
        Operadoras
        {config.temPermissao("admin:operadoras:cadastrar") && (
          <button
            className="btn btn-primary f700-16 mobile"
            onClick={openModal}
          >
            Cadastrar
          </button>
        )}
      </h2>
      <div className="flex jc-sb ai-fc mb-60">
        <div className="container-search-filter flex ai-fc jc-sb gap-25">
          <Search
            onChange={(value) => {
              setSearchLoading(true);
              getSearch(1, value, false);
            }}
            searchOnTyping={isMobile}
            loading={searchLoading}
          />
        </div>

        <div className="flex ai-fc gap-30 desktop">
          {config.temPermissao("admin:operadoras:cadastrar") && (
            <button className="btn btn-primary f700-16" onClick={openModal}>
              Cadastrar
            </button>
          )}
        </div>
      </div>

      {loading ? (
        <div className="loader loader-default is-active" />
      ) : (
        <div>
          <div className="table-mobile">
            {listagem.map((p, i) => {
              return (
                <div className="item pointer" key={`mobile-${i}`}>
                  <div>
                    <div className="fn-16 linha flex jc-sb">
                      Nome
                      <span>{p.nome}</span>
                    </div>
                    <div className="fn-16 linha flex jc-sb">
                      CNPJ
                      <span>{formatCNPJ(p.cnpj) || "-"}</span>
                    </div>
                    <div className="fn-16 linha flex jc-sb">
                      Telefone
                      <span>{formatPhone(p.telefone) || "-"}</span>
                    </div>
                    <div className="fn-16 linha flex jc-sb">
                      E-mail
                      <span>{p.email}</span>
                    </div>
                    <div className="fn-16 linha flex jc-sb">
                      Cidade/UF
                      <span>{p.cidade + " - " + p.uf}</span>
                    </div>
                    <div className="fn-16 linha flex jc-sb">
                      Status
                      <span>
                        <ToggleSwitch
                          type="checkbox"
                          id={"mobile-" + p.id}
                          name="status"
                          disabled
                          value={parseInt(p.status) === 1 ? true : false}
                          texto={["Ativo", "Inativo"]}
                          style={{ "--width": "110px" }}
                          model={async (name, data) => {
                            listagem[i].status = data ? 1 : 2;
                            let request = await business.statusOperadora(
                              listagem[i].status,
                              listagem[i].id
                            );
                            if (request?.response?.status) {
                              setListagem([...listagem]);
                            }
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="w-100 flex jc-fc">
                    <MenuDots
                      row={p}
                      menuItens={menuItens}
                      menuPositionTop={true}
                    />
                  </div>
                </div>
              );
            })}
            {(!listagem || !listagem?.length) && (
              <div className="item pointer" key={`mobile-0`}>
                Nenhum resultado encontrado.
              </div>
            )}
          </div>
          <table className="w-100 table-desktop">
            <thead className="f300-16">
              <tr>
                <th className="bold">Nome</th>
                <th className="text-left bold">CNPJ</th>
                <th className="text-left bold">Telefone</th>
                <th className="text-left bold">E-mail</th>
                <th className="text-left bold">Cidade/UF</th>
                <th className="text-left bold">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {listagem.map((p, i) => {
                return (
                  <tr key={`desktop-${i}`}>
                    <td className="fn-16">{p.nome}</td>
                    <td className="fn-16">{formatCNPJ(p.cnpj) || "-"}</td>
                    <td className="fn-16">{formatPhone(p.telefone) || "-"}</td>
                    <td className="fn-16 underline">{p.email}</td>
                    <td className="fn-16">{p.cidade + " - " + p.uf}</td>
                    <td className="fn-16" style={{ minWidth: "120px" }}>
                      <ToggleSwitch
                        type="checkbox"
                        id={"desktop-" + p.id}
                        name="status"
                        value={parseInt(p.status) === 1 ? true : false}
                        texto={["Ativa", "Inativa"]}
                        style={{ "--width": "110px" }}
                        model={async (name, data) => {
                          listagem[i].status = data ? 1 : 2;
                          let request = await business.statusOperadora(
                            listagem[i].status,
                            listagem[i].id
                          );
                          if (request?.response?.status) {
                            setListagem([...listagem]);
                          }
                        }}
                      />
                    </td>
                    <td className="text-center" style={{ width: "35px" }}>
                      <MenuDots
                        row={p}
                        menuItens={menuItens}
                        menuPositionTop={listagem.length - i <= 2}
                      />
                    </td>
                  </tr>
                );
              })}
              {(!listagem || !listagem?.length) && (
                <tr>
                  <td colSpan={7}>Nenhum resultado encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="component pagination">
            <div className="pagination-container mt-30">
              {console.log(pager.total_pages)}
              {pager.total_pages < 2 ? (
                ""
              ) : (
                <ReactPaginate
                  previousLabel="«"
                  nextLabel="»"
                  breakLabel="..."
                  breakClassName="break-me"
                  page={pager.page - 1 ?? 0}
                  forcePage={pager.page - 1 ?? 0}
                  pageCount={pager.total_pages}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
