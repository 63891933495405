import React, { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import '../../../assets/styles/components/Processo/Historico.scss'
import business from "../../../services/business";
import Loading from "../../Loading";
import moment from 'moment-timezone';
import ModalAlert from "../../Modais/ModalAlert";
import config from "../../../config/config";
import { useAlert } from "react-alert";

moment.tz.setDefault("America/Sao_Paulo");

export default function Historico({ id, render }) {

  const alert = useAlert();

  const [notificacao, setNotificacao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});

  const remover = async (e, item) => {
    e.preventDefault();

    return setModalData({
      text: 'Deseja realmente remover este item do histórico?',
      show: true,
      buttonCancel: true,
      onAccept: async (e) => {
        e.preventDefault();
        let res = await business.removeHistorico(item.id)
        if (res?.errors?.length) {
          (res.errors).forEach(e => alert.error(e));
        } else {
          getSearch();
          alert.success('A comunicação foi removida do histórico com sucesso!');
        }
        setModalData({
          show: false
        });
      },
      onClose: (e) => {
        e.preventDefault();
        setModalData({
          show: false
        });
      }
    })
  }

  const getSearch = useCallback(async () => {
    setLoading(true);
    let response = await business.getNotificacao({
      tipo: [2],
      processo: id,
      historico_visivel: true
    });
    setNotificacao(response)
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getSearch();
  }, [getSearch, render])

  return (

    <div className="junta-historico">
      <ModalAlert title={modalData?.title} text={modalData?.text} show={modalData?.show} onAccept={modalData?.onAccept} onClose={modalData?.onClose} buttonCancel={modalData?.buttonCancel} />
      <h1 className="f600-18 mb-20">Histórico</h1>
      {(loading && notificacao.length) ? (<Loading />) : (
        <div className="overflow-y scrollfit h-100 flex fd-fc">
          {notificacao ? (notificacao || []).map((notificacao) => {
            return <div key={notificacao.id} className="flex ai-fs item gap-20">
              <div className="circle" />
              <div className="flex fd-fc fb-14 pb-25">
                <h4 className="f600-16 blue-text m-0">{notificacao.desc_processo_etapa}</h4>
                <p className="f400-12 mv-5">{notificacao.desc_acao}</p>
                <span className="f400-12 mv-5">{notificacao.nome_usuario}</span>
                <p className="f400-12 mv-5">{moment(notificacao.data_criacao, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs</p>
              </div>
              {([1, 5, 8].includes(parseInt(config.user.tipo))) && <span onClick={(e) => remover(e, notificacao)}><i className="icon icon-trash watermelon i-20 pointer" title="Remover" /></span>}
            </div>
          }) : 'Sem histórico.'}
        </div>
      )}
    </div>
  );
}