import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DadosGerais from "../../components/Processo/Abas/DadosGerais.js";
import Historico from "../../components/Processo/Detalhes/Historico";
import Etapa from "../../components/Processo/Detalhes/Etapa.js";
import Anexos from "../../components/Processo/Abas/Anexos.js";
import Parecer from "../../components/Processo/Abas/Parecer";
import Layout from "../../components/Layout.js";
import ProgressBarList from "../../components/ProgressBarList.js";
import "../../assets/styles/pages/Processo/Detalhes.scss";
import business from "../../services/business.js";
import ModalProcesso from "../../components/Modais/ModalProcesso.js";
import layout from "../../lib/layout.js";
import tools from "../../lib/tools.js";
import config from "../../config/config.js";
import Preloader from "../../components/Preloader.js";
import moment from "moment-timezone";
import ModalResponsaveis from "../../components/Modais/ModalResponsaveis.js";
import ModalConvidados from "../../components/Modais/ModalConvidados.js";
import { useAlert } from "react-alert";
import DadosProcesso from "../../components/Processo/Abas/DadosProcesso.js";
import ModalAlert from "../../components/Modais/ModalAlert.js";
import Chat from "../../components/Processo/Abas/Chat.js";
import Comunicacoes from "../../components/Processo/Abas/Comunicacoes.js";
import NaoAutorizado from "../../components/NaoAutorizado.js";
import Pusher from "pusher-js";

moment.tz.setDefault("America/Sao_Paulo");

export default function DetProcesso({ name }) {
  const defaultValues = {
    id_area_atuacao: "",
    id_operadora: "",
    id_plano: "",
    desc_operadora: "",
    id_processo_tipo: "",
    id_processo_tipo_atendimento: "",
    beneficiario: {
      cpf: "",
      nome: "",
      nascimento: "",
      sexo: "",
      telefone: "",
      telefone2: "",
      email: "",
      email2: "",
      cep: "",
      rua: "",
      numero: "",
      cidade: "",
      bairro: "",
      uf: "",
      carteirinha: "",
    },
    profissional: {
      crm: "",
      nome: "",
      telefone: "",
      telefone2: "",
      email: "",
      email2: "",
    },
    procedimentos: [],
    materiais: [],
    anexos: [],
  };

  layout.openMenu(`/${name}`);

  let { id } = useParams();

  const navigate = useNavigate();
  const alert = useAlert();
  const location = useLocation();
  const hash = location.hash?.replace("#", "");

  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [processoLoaded, setProcessoLoaded] = useState(false);
  const [validacao, setValidacao] = useState({});
  const [updateStatus, setUpdateStatus] = useState(false);
  const [options, setOptions] = useState({});
  const [form, setForm] = useState(defaultValues);
  const [render, setRender] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [showNext, setShowNext] = useState(true);
  const [etapaAtual, setEtapa] = useState({});
  const [salvandoDados, setSalvandoDados] = useState(false);
  const [comentario, setComentario] = useState();

  const [showModalResponsaveis, setShowModalResponsaveis] = useState(false);
  const [showModalConvidados, setShowModalConvidados] = useState(false);
  const [modalData, setModalData] = useState({});

  const voltar = (e) => {
    navigate(`/${name}`);
  };
  document.title = '240588' + id;

  const setScrollStatus = (voltar = false) => {
    let status = document.getElementById("container-status");
    if (voltar) {
      status.scrollLeft -= 265;
    } else {
      status.scrollLeft += 265;
    }
    setShowNext(status.scrollWidth - status.clientWidth !== status.scrollLeft);
    setScroll(status.scrollLeft);
  };

  window.addEventListener("resize", (e) => {
    let status = document.getElementById("container-status");
    if (status && status.length) {
      setShowNext(
        status.scrollWidth - status.clientWidth !== status.scrollLeft
      );
      setScroll(status.scrollLeft);
    }
  });

  const openModal = (event) => {
    event?.preventDefault();
    setShowModal(true);
  };

  const hideModal = (event) => {
    event?.preventDefault();
    setShowModal(false);
  };

  const openModalResponsaveis = async (event = {}) => {
    event?.preventDefault();
    setShowModalResponsaveis(true);
  };
  const hideModalResponsaveis = async (event) => {
    event?.preventDefault();
    setShowModalResponsaveis(false);
  };

  const openModalConvidados = async (event = {}) => {
    event?.preventDefault();
    setShowModalConvidados(true);
  };
  const hideModalConvidados = async (event) => {
    event?.preventDefault();
    setShowModalConvidados(false);
  };

  const [abas, setAbas] = useState([]);

  const [aba, setAba] = useState(
    [
      "dados",
      "processo",
      "parecer",
      "comunicaoes",
      "comentarios",
      "anexos",
    ].includes(hash)
      ? hash
      : "dados"
  );

  const setModel = (name, value) => {
    let keys = name?.split(".") || false;
    if (keys && keys.length > 1) {
      name = keys[0];
      value = { ...form[name], ...{ [keys[1]]: value } };
    }
    if (name === "form") {
      setForm((prevState) => ({ ...prevState, ...value }));
    } else {
      setForm((prevState) => ({ ...prevState, ...{ [name]: value } }));
    }
  };

  const abaActive = () => {
    let abaAtual = abas.find((aba_item) => aba_item.id === aba) || {};

    switch (aba) {
      case "anexos":
        return (
          <Anexos
            form={form}
            validacao={validacao}
            setModel={setModel}
            options={options}
            permitirEdicao={temPermissao(abaAtual, "anexos:editar")}
            className="f600-18 mh-30 mt-20 mb-30"
          />
        );
      case "processo":
        return (
          <DadosProcesso
            salvar={salvar}
            form={form}
            validacao={validacao}
            setModel={setModel}
            options={options}
            permitirEdicao={temPermissao(abaAtual, "processo:editar")}
            updateStatus={updateStatus}
            className="f600-18 mh-30 mt-20 mb-30"
          />
        );
      case "parecer":
        return <Parecer form={form} options={options} setForm={setModel} />;
      case "comunicacoes":
        return (
          <Comunicacoes
            form={form}
            options={options}
            setModel={setModel}
            idComentario={comentario?.id}
          />
        );
      case "comentarios":
        return <Chat id={id} options={options} idComentario={comentario?.id} />;
      default:
        return (
          <DadosGerais
            salvar={salvar}
            form={form}
            startDate={form?.inicio_processo}
            expireDate={form?.expiracao_processo}
            ansDate={form?.data_ans}
            permitirEdicao={temPermissao(abaAtual, "dados:editar")}
            validacao={validacao}
            setModel={setModel}
            options={options}
            updateStatus={updateStatus}
            className="f600-18 mh-30 mt-20 mb-30"
          />
        );
    }
  };

  const title = layout.title(name);

  const [diasAteInicio, setDiasAteInicio] = useState(0);
  const [diasANS, setDiasANS] = useState(0);
  const [diasSaasto, setDiasSaasto] = useState(0);

  config.onChange = () => {
    setRender(tools.uuidv4());
  };

  const simularAvancoEtapas = false;

  const avancarEtapa = async (data, setError) => {
    let formData = tools.clone(form);

    formData.id_processo_etapa_origem = formData.id_processo_etapa || 0;
    formData.id_processo_etapa_status_origem =
      formData.id_processo_etapa_status || 0;
    formData.id_processo_etapa_status_selecionado = data?.status || 0;

    let statusSelecionado = etapaAtual.status_permitidos.find((status) => {
      return parseInt(status.id) === parseInt(data.status);
    });

    let proximaEtapa = form.etapas.find((etapa) => {
      return (
        statusSelecionado?.proxima_etapa &&
        parseInt(etapa.id) === parseInt(statusSelecionado?.proxima_etapa)
      );
    });

    let proximoStatus = proximaEtapa?.status_permitidos.find((etapa) => {
      return (
        statusSelecionado?.proximo_status &&
        parseInt(etapa.id) === parseInt(statusSelecionado?.proximo_status)
      );
    });

    if (proximaEtapa && proximoStatus) {
      formData.id_processo_etapa = proximaEtapa.id;
      formData.desc_processo_etapa = proximaEtapa.nome;
      formData.id_processo_etapa_status = proximoStatus.id;
      formData.desc_processo_etapa_status = proximoStatus.desc;
    } else if (statusSelecionado) {
      formData.id_processo_etapa_status = statusSelecionado.id;
      formData.desc_processo_etapa_status = statusSelecionado.desc;
    } else {
      setError((prevState) => ({
        ...prevState,
        status: "Selecione um status para continuar",
      }));

      return false;
    }

    if (
      formData &&
      [9].includes(parseInt(form.id_processo_etapa)) &&
      [16, 35, 49, 10948].includes(parseInt(data.status)) &&
      !data?.id_desempatador &&
      !data?.auto_escolher_desempatador
    ) {
      setError((prevState) => ({
        ...prevState,
        id_desempatador: "Selecione um desempatador para continuar",
      }));

      return false;
    }

    if (data?.id_desempatador) {
      formData.id_desempatador = data?.id_desempatador;
    }

    setError({});

    let etapaAtiva;

    formData.etapas = formData.etapas.map((etapa) => {
      if (parseInt(formData.id_processo_etapa) === parseInt(etapa.id)) {
        etapaAtiva = etapa.id;
        etapa.ativa = true;
        etapa.descricao = formData.desc_processo_etapa_status;
        etapa.porcentagem = 40;
      } else if (etapaAtiva) {
        etapa.ativa = false;
        etapa.descricao = "Na fila";
        etapa.porcentagem = 0;
      } else {
        etapa.ativa = false;
        etapa.descricao = "Concluída";
        etapa.porcentagem = 100;
      }
      return etapa;
    });

    if (simularAvancoEtapas) {
      setForm(formData);
      hideModal();
    } else {
      setSalvandoDados(true);

      console.log(data.status);
      if ((data.status === 3 || data.status === 4) && data.comentario.length < 1) {
        alert.success("Comentário não pode estar vazio!");
        setSalvandoDados(false);
        return;
      } else if ((data.status === 3 || data.status === 4) && data.comentario.length > 1) {
        let commentario = {
          id_processo: id,
          texto: data.comentario,
        };

        const { response } = await business.addComunicacao(commentario);

        if (response?.id) {
          setComentario(response);
        }

        // reset
        data.comentario = "";
      }

      formData.procedimentos =
        form.procedimentos?.map((item_p) => {
          item_p.data.valor_unitario = tools.prepareValue(
            item_p.data?.valor_unitario || 0
          );
          return item_p.data;
        }) || [];
      formData.materiais =
        form.materiais?.map((item_m) => {
          item_m.data.valor_unitario = tools.prepareValue(
            item_m.data?.valor_unitario || 0
          );
          return item_m.data;
        }) || [];
      formData.valor_total = tools.prepareValue(form.valor_total);

      const { response, errors } = await business.processo(formData, form?.id);

      if (errors && errors?.length) {
        (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach((e) =>
          alert.error(e)
        );
        setSalvandoDados(false);
      } else {
        formData.permissoes = response?.permissoes ?? [];
        formData = tratarProcesso(formData);

        setForm(formData);
        setAbas(response?.abas || []);
        setRender();
        hideModal();
        setSalvandoDados(false);
      }
    }
  };

  // eslint-disable-next-line
  const temPermissao = (abaAtual, key) => {
    return (abaAtual?.permissoes || []).includes(key);
  };

  const focusInput = (name) => {
    if (!document.getElementsByName(name)?.length) {
      return;
    }
    document.getElementsByName(name)[0].focus();
  };

  const salvar = async (e, stage) => {
    e.preventDefault();

    const obrigatorios = [];

    document.querySelectorAll("main [name][required]").forEach((e) => {
      if (e.name && e.style.display !== "none") obrigatorios.push(e.name);
    });

    let validacao = {};
    let validated = true;
    let first = false;

    switch (stage) {
      case 1:
        obrigatorios.forEach((campo, index) => {
          let keys = campo.split(".");
          if (keys.length === 2) {
            if (
              typeof form[keys[0]][keys[1]] === "undefined" ||
              tools.isEmptyNullOrUndefined(form[keys[0]][keys[1]])
            ) {
              validacao[campo] = "Campo Obrigatório";
              validated = false;
              if (!first) {
                focusInput(campo);
                first = true;
              }
            } else if (["cpf"].includes(keys[1])) {
              let validCpf = tools.validarCPF(form[keys[0]][keys[1]]);
              if (!validCpf.valido) {
                validacao[campo] = validCpf.texto;
                validated = false;
              }
            } else if (["email"].includes(keys[1])) {
              let validEmail = tools.validarEmail(form[keys[0]][keys[1]]);
              if (!validEmail) {
                validacao[campo] = "Digite um e-mail válido";
                validated = false;
              }
            } else if (["telefone"].includes(keys[1])) {
              let validarTelefone = tools.validarTelefone(
                form[keys[0]][keys[1]]
              );
              if (!validarTelefone) {
                validacao[campo] = "Digite um telefone válido";
                validated = false;
              }
            } else if (["nascimento"].includes(keys[1])) {
              let validarData = tools.validarData(
                form[keys[0]][keys[1]]
              );
              if (!validarData) {
                validacao[campo] = "Digite uma data válida.";
                validated = false;
              }
            }
          } else if (keys.length > 2) {
            if (
              typeof form[keys[0]][keys[1]]?.data[keys[2]] === "undefined" ||
              tools.isEmptyNullOrUndefined(
                form[keys[0]][keys[1]]?.data[keys[2]]
              )
            ) {
              validacao[campo] = "Campo Obrigatório";
              validated = false;
            }
          } else if (
            typeof form[campo] == "undefined" ||
            tools.isEmptyNullOrUndefined(form[campo])
          ) {
            validacao[campo] = "Campo Obrigatório";
            validated = false;
          }
        });

        setValidacao(validacao);

        if (validated) {
          let payload = { ...form };

          payload.procedimentos =
            form.procedimentos?.map((item_p) => {
              item_p.data.valor_unitario = tools.prepareValue(
                item_p.data.valor_unitario
              );
              return item_p.data;
            }) || [];
          payload.materiais =
            form.materiais?.map((item_m) => {
              item_m.data.valor_unitario = tools.prepareValue(
                item_m.data.valor_unitario
              );
              return item_m.data;
            }) || [];
          payload.valor_total = tools.prepareValue(form.valor_total);

          setUpdateStatus(true);

          const { response, errors } = await business.processo(
            payload,
            form?.id
          );
          if (errors && errors.length) {
            (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach(
              (e) => alert.error(e)
            );
          } else if (response?.id) {
            form.id = response.id;
            form.id_protocolo = response.id_protocolo;
            form.inicio_processo = response.inicio_processo;
            form.profissional.id = response.profissional.id;
            form.id_prestador = response.id_prestador;
            form.auditor.id = response.auditor.id;
            form.id_processo_etapa = response.id_processo_etapa;
            form.desc_processo_etapa = response.desc_processo_etapa;
            form.id_processo_etapa_status = response.id_processo_etapa_status;
            form.desc_processo_etapa_status =
              response.desc_processo_etapa_status;

            setForm(form);

            alert.success("As informações foram salvas");
          } else {
            alert.error("Ocorreu um erro ao salvar os dados");
          }

          setUpdateStatus(false);
        }

        break;
      case 2:
        let verificaQuantidadeValidaProcedimentos = (
          form?.procedimentos || []
        ).filter((item) => {
          return parseInt(item.data.quantidade_solicitada) === 0;
        });

        let verificaQuantidadeValidaMateriais = (form?.materiais || []).filter(
          (item) => {
            return parseInt(item.data.quantidade_solicitada) === 0;
          }
        );

        if (
          (!form?.materiais?.length && !form?.procedimentos?.length) ||
          verificaQuantidadeValidaProcedimentos?.length ||
          verificaQuantidadeValidaMateriais?.length
        ) {
          return setModalData({
            text: "Adicione ao menos um procedimento ou material.",
            show: true,
            buttonCancel: false,
            onAccept: async (e) => {
              setModalData({
                show: false,
              });
            },
          });
        }

        obrigatorios.forEach((campo) => {
          let keys = campo.split(".");
          if (keys.length === 2) {
            if (
              typeof form[keys[0]][keys[1]] === "undefined" ||
              tools.isEmptyNullOrUndefined(form[keys[0]][keys[1]])
            ) {
              validacao[campo] = "Campo Obrigatório";
              validated = false;
              if (!first) {
                focusInput(campo);
                first = true;
              }
            }
          } else if (keys.length > 2) {
            if (
              typeof form[keys[0]][keys[1]]?.data[keys[2]] === "undefined" ||
              tools.isEmptyNullOrUndefined(
                form[keys[0]][keys[1]]?.data[keys[2]]
              )
            ) {
              validacao[campo] = "Campo Obrigatório";
              validated = false;
              focusInput(campo);
            }
          } else if (
            typeof form[campo] == "undefined" ||
            tools.isEmptyNullOrUndefined(form[campo])
          ) {
            validacao[campo] = "Campo Obrigatório";
            validated = false;
            focusInput(campo);
          }
        });

        setValidacao(validacao);

        if (validated) {
          let payload = { ...form };

          payload.procedimentos =
            form.procedimentos?.map((item_p) => {
              item_p.data.valor_unitario = tools.prepareValue(
                item_p.data.valor_unitario
              );
              return item_p.data;
            }) || [];
          payload.materiais =
            form.materiais?.map((item_m) => {
              item_m.data.valor_unitario = tools.prepareValue(
                item_m.data.valor_unitario
              );
              return item_m.data;
            }) || [];
          payload.valor_total = tools.prepareValue(form.valor_total);

          setUpdateStatus(true);

          const { response, errors } = await business.processo(
            payload,
            form?.id
          );

          if (errors.length) {
            (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach(
              (e) => alert.error(e)
            );
          } else {
            form.id = response.id;
            localStorage.setItem("process", JSON.stringify(form));
            alert.success("As informações foram salvas");
          }

          setUpdateStatus(false);
        }
        break;
      case 3:
        break;
      default:
    }

    return validated;
  };

  const enviarProcesso = async (e) => {
    e.preventDefault();

    if (!window.confirm("Você deseja enviar o processo " + form?.id + "?")) {
      return false;
    }

    let payload = { ...form };

    let verificaPendentesEnvio = (payload?.anexos || []).filter((item) => {
      return item.upload === true;
    });

    if (
      !payload?.anexos ||
      !payload?.anexos?.length ||
      verificaPendentesEnvio?.length
    ) {
      return setModalData({
        text: "Adicione ao menos um anexo ao processo.",
        show: true,
        buttonCancel: false,
        onAccept: async (e) => {
          setModalData({
            show: false,
          });
        },
      });
    }

    payload.procedimentos =
      form.procedimentos?.map((item_p) => {
        return item_p.data;
      }) || [];
    payload.materiais =
      form.materiais?.map((item_m) => {
        return item_m.data;
      }) || [];
    payload.id_processo_etapa = 1;
    payload.id_processo_etapa_status = 1;

    setSalvandoDados(true);

    const { response, errors } = await business.processo(payload, form?.id);

    if (errors && errors?.length) {
      (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach((e) =>
        alert.error(e)
      );
      setSalvandoDados(false);
    } else {
      localStorage.setItem("process", JSON.stringify({}));

      response.permissoes = response?.permissoes ?? [];

      setForm(tratarProcesso(response));
      setAbas(response?.abas || []);
      setRender();
      hideModal();
      setSalvandoDados(false);
    }
  };

  const devolverProcesso = async (e) => {
    e.preventDefault();

    if (!window.confirm("Você deseja devolver este processo à säästö?")) {
      return false;
    }

    let payload = { ...form };
    payload.procedimentos =
      form.procedimentos?.map((item_p) => {
        return item_p.data;
      }) || [];
    payload.materiais =
      form.materiais?.map((item_m) => {
        return item_m.data;
      }) || [];
    payload.id_processo_etapa_status = 41;

    setSalvandoDados(true);

    const { response, errors } = await business.processo(payload, form?.id);

    if (errors && errors?.length) {
      (errors || ["Ocorreu um erro ao tentar realizar ação"]).forEach((e) =>
        alert.error(e)
      );
      setSalvandoDados(false);
    } else {
      localStorage.setItem("process", JSON.stringify({}));

      setForm(response);
      setAbas(response?.abas || []);
      setRender();
      hideModal();
      setSalvandoDados(false);
    }
  };

  const tratarProcesso = (processo) => {
    [
      "data_ans",
      "data_atualizacao",
      "data_criacao",
      "expiracao_processo",
      "inicio_processo",
    ].forEach((e) => {
      if (
        processo &&
        typeof processo[e] !== "undefined" &&
        !tools.isEmptyNullOrUndefined(processo[e])
      ) {
        processo[`${e}_original`] = processo[e];
        processo[e] = moment(processo[e]).format("YYYY-MM-DD");
      }
    });
    if (
      processo?.beneficiario &&
      !tools.isEmptyNullOrUndefined(processo.beneficiario?.nascimento)
    ) {
      processo.beneficiario.nascimento = moment(
        processo.beneficiario.nascimento
      ).format("YYYY-MM-DD");
    }

    if (processo?.procedimentos)
      processo.procedimentos = processo?.procedimentos?.map((item) => {
        return {
          id: item.id_procedimento,
          procedimento: {
            id: item.id_procedimento,
            nome: item.desc_procedimento,
            codigo: item.codigo,
          },
          data: {
            id_procedimento: item.id_procedimento,
            quantidade_solicitada: item.quantidade_solicitada,
            quantidade_aprovada: item.quantidade_aprovada,
            quantidade_pos_analise: item.quantidade_pos_analise,
            id_processo_motivo_divergencia:
              item?.id_processo_motivo_divergencia || 0,
            valor_unitario: item?.valor_unitario ?? 0.00,
            rol: item?.rol || 0,
            observacao: item.observacao,
            justificativa_analise: item.justificativa_analise,
          },
        };
      });

    if (processo?.materiais)
      processo.materiais = processo?.materiais?.map((item) => {
        return {
          id: item.id_material,
          material: {
            id: item.id_material,
            nome: item.desc_material,
            codigo: item.codigo,
          },
          data: {
            id_material: item.id_material,
            quantidade_solicitada: item.quantidade_solicitada,
            quantidade_aprovada: item.quantidade_aprovada,
            quantidade_pos_analise: item.quantidade_pos_analise,
            valor_unitario: item?.valor_unitario ?? 0.00,
            id_processo_motivo_divergencia:
              item?.id_processo_motivo_divergencia || 0,
            rol: item.rol,
            observacao: item.observacao,
            justificativa_analise: item.justificativa_analise,
          },
        };
      });

    return processo;
  };

  useEffect(() => {
    const getProcesso = async (id_processo) => {
      if (id_processo === null || processoLoaded) {
        return;
      }

      setProcessoLoaded(true);

      let processo = {};

      if (id_processo) {
        await business
          .getProcesso(id_processo)
          .then((l) => (processo = tratarProcesso(l)));

        if (!processo?.id) {
          return navigate(`/${name}`);
        }

        setForm(processo);
        setAbas(processo?.abas || []);
      }
    };

    (async () => {
      getProcesso(id);
    })();
  }, [processoLoaded, navigate, name, id]);

  useEffect(() => {
    const getOptions = async () => {
      if (optionsLoaded) {
        return;
      }

      setOptionsLoaded(true);

      let promises = [],
        estados = [],
        sexos = [],
        tiposAnexo = [],
        tiposAnexoCom = [],
        tiposProcesso = [],
        tiposAtendimento = [],
        motivosDeDivergenciaP = [],
        motivosDeDivergenciaPDivergencia = [],
        motivosDeDivergenciaM = [],
        motivosDeDivergenciaMDivergencia = [],
        tipoAnalise = [],
        planoBeneficiario = [];

      estados = business.getEstados();
      sexos = business.getSexo();
      motivosDeDivergenciaP = business.getMotivosDeDivergenciaP();
      motivosDeDivergenciaM = business.getMotivosDeDivergenciaM();
      motivosDeDivergenciaPDivergencia =
        business.getMotivosDeDivergenciaPDivergencia();

      motivosDeDivergenciaMDivergencia =
        business.getMotivosDeDivergenciaMDivergencia();
      tipoAnalise = business.getTipoAnalise();
      planoBeneficiario = business.getPlanoBeneficiario();

      promises.push(
        business.getTipoAnexoCom().then((res) => (tiposAnexoCom = res))
      );
      promises.push(business.getTipoAnexo().then((res) => (tiposAnexo = res)));
      promises.push(
        business.getTiposProcesso().then((res) => (tiposProcesso = res))
      );
      promises.push(
        business.getTiposAtendimento().then((res) => (tiposAtendimento = res))
      );

      await Promise.all(promises);

      setOptions({
        ...{
          estados,
          sexos,
          tiposAnexo,
          tiposAnexoCom,
          tiposProcesso,
          tiposAtendimento,
          motivosDeDivergenciaP,
          motivosDeDivergenciaPDivergencia,
          motivosDeDivergenciaM,
          motivosDeDivergenciaMDivergencia,
          tipoAnalise,
          planoBeneficiario
        },
        ...options,
      });
    };

    (async () => {
      getOptions();
    })();
    // eslint-disable-next-line
  }, [optionsLoaded]);

  useEffect(() => {
    setEtapa(
      (form?.etapas || []).find((etapa) => {
        return parseInt(etapa.id) === parseInt(form?.id_processo_etapa);
      })
    );

    tools
      .diasUteisEntreDatas(form?.data_atendimento, form?.inicio_processo)
      .then((total) => setDiasAteInicio(total)); //barra cinza
    if (!form?.data_conclusao) {
      tools
        .diasUteisEntreDatas(form?.data_atendimento, config.dataHoraAtual())
        .then((total) => setDiasANS(total)); //barra azul escuro
      tools
        .diasUteisEntreDatas(
          form?.inicio_processo_original,
          config.dataHoraAtual()
        )
        .then((total) => setDiasSaasto(total - form?.pausa_dias - 1)); //barra azul claro
    } else {
      tools
        .diasUteisEntreDatas(form?.data_atendimento, form?.data_conclusao)
        .then((total) => setDiasANS(total)); //barra azul escuro
      tools
        .diasUteisEntreDatas(
          form?.inicio_processo_original,
          form?.data_conclusao
        )
        .then((total) => setDiasSaasto(total - form?.pausa_dias - 1)); //barra azul claro
    }
  }, [form, render, form?.responsaveis?.id_desempatador]);

  const [showHistorico, setShowHistorico] = useState(false);

  const toggleComponent = () => {
    setShowHistorico(!showHistorico);
  };

  useEffect(() => {
    const container = document.getElementById("container-status");
    const key = etapaAtual?.key;
    if (
      container &&
      key !== undefined &&
      (etapaAtual?.porcentagem === 40 ||
        key === 11 ||
        etapaAtual?.nome === "Cancelado" ||
        key === 10 ||
        etapaAtual?.nome === "Finalizado")
    ) {
      container.scrollLeft = key * 270;
    }
  }, [etapaAtual]);

  const [updatePushEnable, setUpdatePushEnable] = useState(false);

  useEffect(() => {
    (async () => {
      if (updatePushEnable || !form?.hash_signature) {
        return;
      }

      setUpdatePushEnable(true);

      const pusher = new Pusher("cc3ec1a7305f21c3275e", {
        cluster: "sa1",
        encrypted: true,
      });

      console.log(`Push: assinando o canal "saa-processo-${id}"`);

      const channel_history = pusher.subscribe(`saa-processo-${id}`);

      channel_history.bind("update", function (data) {
        const { id_usuario, hash_signature } = JSON.parse(data.message);
        if (
          config.user?.id &&
          parseInt(config.user.id) !== parseInt(id_usuario)
        ) {
          console.log(
            `Push: notificando no evento "saa-processo-${id}:update"`,
            hash_signature,
            form?.hash_signature
          );

          setModalData({});

          if (form?.hash_signature !== hash_signature)
            return setModalData({
              text: "O processo foi alterado. Clique em OK para atualizar.",
              show: true,
              buttonCancel: false,
              onAccept: async (e) => {
                e.preventDefault();
                window.location.reload();
              },
              onClose: async (e) => {
                e.preventDefault();
                window.location.reload();
              },
            });
        }
      });
    })();
  }, [id, form?.hash_signature, updatePushEnable]);

  return (
    <Layout id="detalhes" className="page detalhes">
      {config.user.tipo === "6" &&
        form?.responsaveis?.id_desempatador &&
        parseInt(config.user.id) !==
        parseInt(form.responsaveis.id_desempatador) ? (
        <NaoAutorizado />
      ) : (
        <>
          <ModalAlert
            title={modalData?.title}
            text={modalData?.text}
            show={modalData?.show}
            onAccept={modalData?.onAccept}
            onClose={modalData?.onClose}
            buttonCancel={modalData?.buttonCancel}
          />
          {!options?.estados ? <Preloader /> : ""}
          {
            <ModalProcesso
              salvandoDados={salvandoDados}
              show={showModal}
              hideModal={hideModal}
              etapa={etapaAtual}
              processo={form}
              avancarEtapa={avancarEtapa}
            />
          }
          {showModalResponsaveis && (
            <ModalResponsaveis
              show={showModalResponsaveis}
              hideModal={hideModalResponsaveis}
              processo={form}
            />
          )}
          {showModalConvidados && (
            <ModalConvidados
              show={showModalConvidados}
              hideModal={hideModalConvidados}
              processo={form}
            />
          )}
          <p className="page-title">{title}</p>
          <h1 className="flex ai-fc mb-30 gap-60">
            <div className="flex ai-fc f600-22 gap-10">
              <i
                className="icon icon-arrow-left i-25 pointer"
                onClick={voltar}
              />{" "}
              {form?.id_protocolo}
            </div>
            {form?.id_processo_tipo === "1" &&
              form?.id_processo_etapa !== "5" &&
              form?.id_processo_etapa !== "12" && (
                <div className="flex fd-fc w-100">
                  {diasSaasto <= 2 ? (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo säästö:"
                      label={`${diasSaasto} / 2`}
                      list={[
                        {
                          bgcolor: "#0d6efd",
                          borderRadius: "8px",
                          completed: tools.porcentagem(2, diasSaasto),
                          title: `Decorridos ${diasSaasto} de 2 dias do prazo säästö.`,
                        },
                      ]}
                    />
                  ) : (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo säästö:"
                      label={`${diasSaasto} / 2`}
                      list={[
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          completed: 100,
                          title: `Expirado prazo säästö.`,
                        },
                      ]}
                    />
                  )}
                </div>
              )}

            {form?.id_processo_tipo === "2" &&
              form?.id_processo_etapa !== "5" &&
              form?.id_processo_etapa !== "12" && (
                <div className="flex fd-fc w-100">
                  {diasSaasto <= 1 ? (
                    <ProgressBarList
                      className="w-100"
                      list={[
                        {
                          bgcolor: "#0d6efd",
                          borderRadius: "8px",
                          completed: tools.porcentagem(24, 2),
                          title: `Decorridos ${diasSaasto} de 1 dia do prazo säästö.`,
                        },
                      ]}
                      slim={10}
                      initlabel="Prazo säästö:"
                      label="24hrs"
                    />
                  ) : (
                    <ProgressBarList
                      className="w-100"
                      list={[
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          completed: tools.porcentagem(24, 2),
                          title: `Expirado prazo säästö.`,
                        },
                      ]}
                      slim={10}
                      initlabel="Prazo säästö:"
                      label="24hrs"
                    />
                  )}
                </div>
              )}

            {form?.id_processo_tipo === "3" &&
              form?.id_processo_tipo_atendimento === "1" &&
              form?.id_processo_etapa !== "5" &&
              form?.id_processo_etapa !== "12" && (
                <div className="flex fd-fc w-100">
                  <span className="fn-12 text-center">
                    Processo encaminhado para säästö no {diasAteInicio}º dia do
                    prazo ANS.
                  </span>
                  {diasANS < 21 ? (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo ANS:"
                      label={`${diasANS} / 21`}
                      list={[
                        {
                          bgcolor: "#0d6efd",
                          borderRadius: "8px",
                          completed: tools.porcentagem(21, diasANS),
                          title: `Decorridos ${diasANS} de 21 dias do prazo ANS.`,
                        },
                        {
                          bgcolor: "#c9c9c9",
                          borderRadius: "8px 0px 0px 8px",
                          completed: tools.porcentagem(21, diasAteInicio),
                          title: `Processo encaminhado para säästö no ${diasAteInicio}º dia do prazo ANS.`,
                        },
                      ]}
                    />
                  ) : (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo ANS:"
                      label={`${diasANS} / 21`}
                      list={[
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          completed: 100,
                          title: `Expirado prazo de 21 dias da ANS.`,
                        },
                      ]}
                    />
                  )}
                  {![3, 4, 19, 24, 26].includes(
                    parseInt(form?.id_processo_etapa_status)
                  ) &&
                    (diasSaasto <= 7 &&
                      form?.expiracao_processo >= config.dataAtual()) ? (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo säästö:"
                      label={`${diasSaasto < 0 ? 0 : diasSaasto} / 7`}
                      list={[
                        {
                          bgcolor: `${diasSaasto >= 1 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "0px",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 2 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "14.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 3 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "28.4%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 4 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "42.6%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 5 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "56.8%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 6 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "71%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 7 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "85.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                      ]}
                    />
                  ) : form?.id_processo_etapa_status === "3" ||
                    form?.id_processo_etapa_status === "4" ||
                    form?.id_processo_etapa_status === "19" ||
                    form?.id_processo_etapa_status === "24" ||
                    form?.id_processo_etapa_status === "26" ? (
                    <span className="fn-18 text-center">
                      Processo pausado, aguardando interação da operadora.
                    </span>
                  ) : (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo säästö:"
                      label={`${diasSaasto < 0 ? 0 : diasSaasto} / 7`}
                      list={[
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "0px",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "14.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "28.4%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "42.6%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "56.8%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "71%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "85.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                      ]}
                    />
                  )}
                </div>
              )}
            {form?.id_processo_tipo === "3" &&
              form?.id_processo_tipo_atendimento === "2" &&
              form?.id_processo_etapa !== "5" &&
              form?.id_processo_etapa !== "12" && (
                <div className="flex fd-fc w-100">
                  {diasANS < 10 ? (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo ANS:"
                      label={`${diasANS} / 10`}
                      list={[
                        {
                          bgcolor: "#0d6efd",
                          borderRadius: "8px",
                          completed: tools.porcentagem(21, diasANS),
                          title: `Decorridos ${diasANS} de 10 dias do prazo ANS.`,
                        },
                        {
                          bgcolor: "#c9c9c9",
                          borderRadius: "8px 0px 0px 8px",
                          completed: tools.porcentagem(21, diasAteInicio),
                          title: `Decorridos ${diasAteInicio} dias antes do cadastro do processo.`,
                        },
                      ]}
                    />
                  ) : (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo ANS:"
                      label={`${diasANS} / 10`}
                      list={[
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          completed: 100,
                          title: `Expirado prazo de 21 dias da ANS.`,
                        },
                      ]}
                    />
                  )}

                  {![3, 4, 19, 24, 26].includes(
                    parseInt(form?.id_processo_etapa_status)
                  ) &&
                    (diasSaasto <= 7 &&
                      form?.expiracao_processo >= config.dataAtual()) ? (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo säästö:"
                      label={`${diasSaasto < 0 ? 0 : diasSaasto} / 7`}
                      list={[
                        {
                          bgcolor: `${diasSaasto >= 1 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "0px",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 2 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "14.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 3 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "28.4%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 4 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "42.6%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 5 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "56.8%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 6 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "71%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                        {
                          bgcolor: `${diasSaasto >= 7 ? "#84a1ff" : "#fff"}`,
                          borderRadius: "8px",
                          left: "85.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Decorridos ${diasSaasto} de 7 dias do prazo säästö.`,
                        },
                      ]}
                    />
                  ) : form?.id_processo_etapa_status === "3" ||
                    form?.id_processo_etapa_status === "4" ||
                    form?.id_processo_etapa_status === "19" ||
                    form?.id_processo_etapa_status === "24" ||
                    form?.id_processo_etapa_status === "26" ? (
                    <span className="fn-18 text-center">
                      Processo pausado, aguardando interação da operadora.
                    </span>
                  ) : (
                    <ProgressBarList
                      className="w-100"
                      slim={10}
                      initlabel="Prazo säästö:"
                      label={`${diasSaasto < 0 ? 0 : diasSaasto}/7`}
                      list={[
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "0px",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "14.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "28.4%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "42.6%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "56.8%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "71%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                        {
                          bgcolor: "#FF5468",
                          borderRadius: "8px",
                          left: "85.2%",
                          completed: tools.porcentagem(7.5, 1),
                          title: `Expirado prazo de 7 dias da säästö`,
                        },
                      ]}
                    />
                  )}
                </div>
              )}
          </h1>
          {form?.etapas && (
            <div className="w-100 grid col-12 fn-14 jc-fc basic-info">
              <span className="span-3">
                Beneficiário(a): <strong>{form?.beneficiario?.nome}</strong>
              </span>
              <span className="span-3">
                Idade:{" "}
                <strong>
                  {tools.calcularIdade(form?.beneficiario?.nascimento)} anos{" "}
                </strong>
                Sexo:{" "}
                <strong>
                  {form?.beneficiario?.sexo === "M" ? "Masculino" : "Feminino"}
                </strong>
              </span>
              <span className="span-4">
                Especialidade: <strong>{form?.desc_especialidade}</strong>
              </span>
              <span className="span-2">
                Prazo säästö:{" "}
                <strong style={{ color: "#003cff" }}>
                  {tools.formatDate(form?.expiracao_processo)}
                </strong>
              </span>

              <span className="span-3">
                PA : <strong>Dr.(a) {form?.profissional?.nome}</strong>
              </span>
              <span className="span-3">
                Operadora: <strong>{form?.desc_operadora}</strong>
              </span>
              <span className="span-4">
                Área de atuação: <strong>{form?.desc_area_atuacao}</strong>
              </span>
              <span className="span-2">
                Prazo ANS:{" "}
                <strong style={{ color: "#003cff" }}>
                  {tools.formatDate(form?.data_ans)}
                </strong>
              </span>
            </div>
          )}
          <div className="container-status relative">
            {scroll > 0 && (
              <div
                className="arrow-previous"
                onClick={() => setScrollStatus(true)}
              >
                <i className="icon icon-arrow-left i-30" />
              </div>
            )}
            {etapaAtual?.id > 0 && (
              <div id="container-status" className="lista-status">
                {(form?.etapas || []).map((etapa) => {
                  if (etapaAtual?.id && etapaAtual.id === 4 && etapa.ativa) {
                    etapa.porcentagem = 100;
                  }

                  if (etapaAtual?.id && etapaAtual.id === 4 && etapa.id === 5) {
                    return "";
                  }

                  if (etapaAtual?.id && etapaAtual.id === 5 && etapa.ativa) {
                    etapa.porcentagem = 100;
                  }

                  if (etapaAtual?.id && etapaAtual.id === 5 && etapa.id === 4) {
                    return "";
                  }

                  return (
                    <Etapa
                      key={etapa.key}
                      item={etapa}
                      marcadorSLA={form.marcador_sla}
                    />
                  );
                })}
              </div>
            )}
            {etapaAtual?.id > 0 && showNext && (
              <div
                className="arrow-next"
                onClick={() => setScrollStatus(false)}
              >
                <i className="icon icon-arrow-left i-30 rotate-180" />
              </div>
            )}
          </div>
          <div className="flex jc-sb ai-fc mt-30">
            <ul className="abas gap-5">
              {(abas || []).map((row_aba) => {
                return (
                  <li
                    className={`f400-16 ${aba === row_aba.id ? "active" : ""}`}
                    key={row_aba.id}
                    onClick={() => setAba(row_aba.id)}
                  >
                    {row_aba.nome}
                    {row_aba.alertas > 0 && (
                      <span className="f700-12">{row_aba.alertas}</span>
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="flex ai-fc">
              <div className="flex sm-gap mr-20">
                {config.temPermissao(
                  "processo:processo_convidados:visualizar"
                ) &&
                  form?.id_processo_tipo === "3" && (
                    <span className="btn-mais-info">
                      <i
                        className="icon icon-users-alt i-20 pointer"
                        title="Convidados"
                        onClick={openModalConvidados}
                      ></i>
                    </span>
                  )}
                <span className="btn-mais-info">
                  <i
                    className="icon icon-users i-20 pointer"
                    title="Responsáveis"
                    onClick={openModalResponsaveis}
                  ></i>
                </span>
                {parseInt(config.user.tipo) !== 2 &&
                  parseInt(config.user.tipo) !== 3 &&
                  parseInt(config.user.tipo) !== 6 && (
                    <span
                      className="btn-mais-info f700-16"
                      onClick={toggleComponent}
                    >
                      <i
                        className="icon icon-book-alt i-20 pointer"
                        title="Histórico"
                      ></i>
                    </span>
                  )}
              </div>
              {etapaAtual?.id &&
                ((![4, 5].includes(etapaAtual.id) &&
                  (![3, 4, 26].includes(
                    parseInt(form?.id_processo_etapa_status)
                  ) ||
                    parseInt(config?.user?.tipo) === 1)) ||
                  (![5].includes(etapaAtual.id) &&
                    parseInt(form?.id_processo_tipo) === 1 &&
                    parseInt(config?.user?.tipo) === 1)) &&
                form?.permissoes?.includes("etapa:avancar") && (
                  <div className="relative btn-avancar">
                    <button
                      className="btn btn-primary f700-16"
                      onClick={openModal}
                    >
                      Avançar
                    </button>
                  </div>
                )}
              {form?.id &&
                parseInt(form?.id_processo_etapa) === 12 &&
                parseInt(form?.id_processo_etapa_status) === 24 && (
                  <div className="relative btn-avancar">
                    <button
                      className="btn btn-primary f700-16"
                      onClick={enviarProcesso}
                    >
                      Enviar processo
                    </button>
                  </div>
                )}
              {form?.id &&
                (config?.user?.tipo === "2" || config?.user?.tipo === "3") &&
                (parseInt(form?.id_processo_etapa_status) === 3 ||
                  parseInt(form?.id_processo_etapa_status) === 4 ||
                  parseInt(form?.id_processo_etapa_status) === 26) && (
                  <div className="relative btn-avancar">
                    <button
                      className="btn btn-primary f700-16"
                      onClick={devolverProcesso}
                    >
                      Devolver à säästö
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="mt-30 flex jc-sb gap-20">
            <div className="body">
              <div className="overflow-y scrollfit h-100">
                {form?.desc_operadora ? abaActive() : ""}
              </div>
            </div>
            {showHistorico && <Historico id={id} render={render} />}
          </div>
        </>
      )}
    </Layout>
  );
}
