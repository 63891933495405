import { getAPIClient } from "./axios.js";

export const api = getAPIClient();

const business = {
  async auth(data) {
    return api
      .post("/autenticar", data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async recover(data) {
    return api
      .post("/recuperar-conta", data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async processo(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/processo${id ? `/${id}` : ""}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async cancelarProcesso(id) {
    return api
      .post(`/processo/${id}/cancelar`, {})
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async cancelarPosParecerProcesso(id) {
    return api
      .post(`/processo/${id}/cancelarPosParecer`, {})
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async duplicarProcesso(id, tipo) {
    return api
      .post(`/processo/${id}/duplicar`, { id_processo_tipo: tipo })
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getProcesso(id) {
    return api
      .get(`/processo/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getProcessoByProtocolo(id_protocolo, hash, access_token) {
    return api
      .get(
        `/protocolo/${id_protocolo}?hash=${hash}&access_token=${access_token}`
      )
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async aceitouParticipar(id_protocolo, hash) {
    return api
      .put(`/protocolo/${id_protocolo}/aceitar?hash=${hash}`, {})
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async recusouParticipar(id_protocolo, hash) {
    return api
      .put(`/protocolo/${id_protocolo}/recusar?hash=${hash}`, {})
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async processoAuth(id_protocolo, hash, data = {}) {
    return api
      .post(`/protocolo/${id_protocolo}/auth?hash=${hash}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async buscarDesempatador(id_processo, texto = "", limite = 4, pagina = 1) {
    return api
      .get(
        `/processo/${id_processo}/desempatadores/buscar?texto=${texto}&limite=${limite}&pagina=${pagina}`
      )
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getDesempatadores(texto = "", limite = 5, pagina = 1) {
    return api
      .get(
        `/relatorio/financeiro/desempatador?texto=${texto}&limite=${limite}&pagina=${pagina}`
      )
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async escolherDesempatador(id_protocolo, hash, access_token, item = {}) {
    return api
      .put(
        `/protocolo/${id_protocolo}/desempatador?hash=${hash}&access_token=${access_token}`,
        {
          id_usuario: item.id,
        }
      )
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async abstencao(id_protocolo, hash, access_token, item = {}) {
    return api
      .put(
        `/protocolo/${id_protocolo}/abstencao?hash=${hash}&access_token=${access_token}`,
        {}
      )
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async consenso(id_protocolo, hash, access_token, item = {}) {
    return api
      .put(
        `/protocolo/${id_protocolo}/consenso?hash=${hash}&access_token=${access_token}`,
        {}
      )
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async aceitaConviteManual(id_notificacao_envio) {
    return api
      .put(`notificacao-desempatador/aceitar/${id_notificacao_envio}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async recusaConviteManual(id_notificacao_envio) {
    return api
      .put(`notificacao-desempatador/recusar/${id_notificacao_envio}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAllProcessos(pagina = 1, limite = 10, texto = "", filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(
        `/processo?${filtros}&limite=${limite}&pagina=${pagina}&texto=${texto}`
      )
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async buscaProcesso(pagina = 1, limite = 10, texto = "", filtro = {}) {
    return api
      .get(`/processo/buscar?limite=${limite}&pagina=${pagina}&texto=${texto}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAllUsuarios(pagina = 1, limite = 10, texto = "", tipo = "", especialidade = "", areaAtuacao = "", operadora = "") {
    return api
      .get(`/usuario?limite=${limite}&pagina=${pagina}&texto=${texto}&tipo=${tipo}&especialidade=${especialidade}&areaatuacao=${areaAtuacao}&operadora=${operadora}`)
      .then((a) => {
        return a.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getUsuario(id) {
    return api
      .get(`/usuario/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async usuario(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/usuario${id ? `/${id}` : ""}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async statusUsuario(status, id) {
    return api
      .put(`/usuario/${id}/status`, {
        status: status,
      })
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async alterarSenha(senha, nova_senha) {
    return api
      .put(`/usuario/alterar-senha`, { senha, nova_senha })
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async removerUsuario(id) {
    return api
      .delete(`/usuario/${id}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async perfil(id) {
    return api
      .get(`/perfil/${id}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getDashboard(filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/dashboard?${filtros}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getCpf(cpf) {
    return api
      .get(`/beneficiario/cpf/${cpf}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getCrm(crm, uf) {
    return api
      .get(`/profissional/crm/${crm}/${uf}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getTiposAtendimento() {
    return api
      .get(`/atendimento`)
      .then((a) => {
        return a.data.response?.map((r) => {
          return { id: r.id, nome: r.desc };
        });
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getTiposProcesso() {
    return api
      .get(`/processo-tipos`)
      .then((a) => {
        return a.data.response?.map((r) => {
          return { id: r.id, nome: r.desc };
        });
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getRoles() {
    return api
      .get(`/usuario-tipos`)
      .then((a) => {
        return a.data.response?.map((r) => {
          return {
            id: r.id,
            nome: r.desc,
          };
        });
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getAreaAtuacao(
    texto = "",
    limite = 5,
    pagina = 1,
    id_especialidade = null
  ) {
    let params = "";
    if (id_especialidade) {
      params += `&id_especialidade=${id_especialidade}`;
    }
    return api
      .get(
        `/area-atuacao?texto=${texto}&limite=${limite}&pagina=${pagina}${params}`
      )
      .then((a) =>
        a.data.response.map((r) => {
          return {
            value: r.id,
            id: r.id,
            label: r.desc,
            nome: r.desc,
          };
        })
      )
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getEspecialidade(
    texto = "",
    limite = 5,
    pagina = 1,
    id_especialidade = null
  ) {
    let params = "";
    if (id_especialidade) {
      params += `&id_especialidade=${id_especialidade}`;
    }
    return api
      .get(
        `/especialidade?texto=${texto}&limite=${limite}&pagina=${pagina}${params}`
      )
      .then((a) =>
        a.data.response.map((r) => {
          return {
            value: r.id,
            id: r.id,
            label: r.desc,
            nome: r.desc,
          };
        })
      )
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getAllProcedimentos(pagina = 1, limite = 10, texto = "") {
    return api
      .get(`/procedimento?limite=${limite}&pagina=${pagina}&texto=${texto}`)
      .then((a) => {
        return a.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getProcedimento(texto = "", limite = 5, pagina = 1) {
    return api
      .get(`/procedimento?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => {
        return a.data.response.map((r) => {
          return {
            id: r.id,
            codigo: r.codigo,
            nome: r.desc,
            valor_unitario: r.valor_unitario,
            cobertura_rol: r.cobertura_rol,
            dut: r.dut,
          };
        });
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async addProcedimento(data) {
    let metodo = "post";
    return api[metodo](`/procedimento`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getProcedimentoById(id) {
    return api
      .get(`/procedimento/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAllProcedimentosAdmin(pagina = 1, limite = 10, texto = "") {
    return api
      .get(`/procedimento-admin?limite=${limite}&pagina=${pagina}&texto=${texto}`)
      .then((a) => {
        return a.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async addProcedimentoAdmin(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/procedimento${id ? `/${id}` : "-admin/"}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getOperadora(texto = "", limite = 10, pagina = 1) {
    return api
      .get(`/operadora?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getAllOperadora(pagina = 1, limite = 10, texto = "") {
    return api
      .get(`/operadora?limite=${limite}&pagina=${pagina}&texto=${texto}`)
      .then((a) => {
        return a.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getOperadoraById(id) {
    return api
      .get(`/operadora/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async addOperadora(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/operadora${id ? `/${id}` : ""}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getResponsaveis(id) {
    return api
      .get(`/processo-responsaveis/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getResponsaveisAssistente(texto = "", limite = 200, pagina = 1) {
    return api
      .get(`usuario/assistentes/buscar?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getResponsaveisEnfermeira(texto = "", limite = 200, pagina = 1) {
    return api
      .get(`usuario/enfermeiras/buscar?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getResponsaveisDesempatador(id, texto = "", limite = 250, pagina = 1) {
    return api
      .get(`/processo/${id}/desempatadores/buscar?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async putResponsaveis(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/processo-responsaveis/${id}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getResponsaveisByOperadora(id_operadora, texto = "") {
    return api
      .get(`/operadora-responsaveis/${id_operadora}?texto=${texto}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async statusOperadora(status, id) {
    return api
      .put(`/operadora/${id}/status`, {
        status: status,
      })
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getEspecialidades(texto = "", limite = 5, pagina = 1, id_area_atuacao) {
    let params = "";
    if (id_area_atuacao) {
      params += `&id_area_atuacao=${id_area_atuacao}`;
    }
    return api
      .get(
        `/especialidade?texto=${texto}&limite=${limite}&pagina=${pagina}${params}`
      )
      .then((a) => {
        return a.data.response.map((r) => {
          return {
            id: r.id,
            nome: r.desc,
          };
        });
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getProfissional(texto = "", limite = 5, pagina = 1) {
    return api
      .get(`/profissional?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getAllMateriais(pagina = 1, limite = 10, texto = "") {
    return api
      .get(`/material?limite=${limite}&pagina=${pagina}&texto=${texto}`)
      .then((a) => {
        return a.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getMateriais(texto = "", limite = 5, pagina = 1) {
    return api
      .get(`/material?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => {
        return a.data.response.map((r) => {
          return {
            id: r.id,
            codigo: r.codigo,
            nome: r.desc,
            tipo: r.desc_materiais_tipo,
            valor_unitario: r.valor_unitario,
          };
        });
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async addMaterial(data) {
    let metodo = "post";
    return api[metodo](`/material`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getMaterialById(id) {
    return api
      .get(`/material/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAllMateriaisAdmin(pagina = 1, limite = 10, texto = "") {
    return api
      .get(`/material-admin?limite=${limite}&pagina=${pagina}&texto=${texto}`)
      .then((a) => {
        return a.data;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async addMaterialAdmin(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/material${id ? `/${id}` : "-admin/"}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getNotificacao(filtro = {}, limite = 50) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/notificacao?${filtros}&limite=${limite}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async setNotificacaoLida(id) {
    return api
      .post(`notificacao/${id}/lida`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getNotificacaoPorID(id) {
    return api
      .get(`notificacao-disparo/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async removeNotificacao(id, id_notificao_canal) {
    return api
      .delete(`/notificacao/${id}/${id_notificao_canal}`, {})
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getNotificacaoDisparos(filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/notificacao-disparo?${filtros}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getComunicacao(filtro = {}, limite = 50) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/comunicacao?${filtros}&limite=${limite}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getComunicacaoPorProcesso(id, filtro = {}, limite = 50) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/processo/${id}/comunicacao?${filtros}&limite=${limite}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async addComunicacao(data) {
    let metodo = "post";
    return api[metodo](`/comunicacao`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async removeHistorico(id) {
    return api
      .delete(`/notificacao-historico/${id}`, {})
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getDossie(id) {
    return api
      .get(`comunicacao-dossie/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getDossieHistorico(id) {
    return api
      .get(`comunicacao-dossie-historico/${id}`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getConvidados(id, filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/processo-pd-convidados/${id}?${filtros}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async getParecer(id) {
    return api
      .get(`/processo/${id}/parecer`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getParecerTotais(id) {
    return api
      .get(`/processo/${id}/parecer-totais`)
      .then((a) => a.data.response)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAllParecer(filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/parecer?${filtros}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async addParecer(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/parecer${id ? `/${id}` : ""}`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAnexos(id, id_grupo = 1) {
    return api
      .get(`/processo/${id}/anexo?id_grupo=${id_grupo}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async removerAnexo(id_processo, anexo) {
    return api
      .delete(
        `/processo/${id_processo}/anexo/${anexo.id}/hash/${anexo.identificador}`
      )
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getAllRelatorios(pagina = 1, limite = 10, filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/relatorio?${filtros}&limite=${limite}&pagina=${pagina}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async exportRelatorio(filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/relatorio/exportar?${filtros}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getRelatorioFinanceiro(pagina = 1, limite = 10, filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/relatorio/financeiro?${filtros}&limite=${limite}&pagina=${pagina}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getCrmCro(texto = "", limite = 10, pagina = 1) {
    return api
      .get(`/relatorio/crm/cro?texto=${texto}&limite=${limite}&pagina=${pagina}`)
      .then((a) => {
        return a.data.response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data;
        }
      });
  },

  async exportRelatorioFinanceiro(filtro = {}) {
    let filtros =
      typeof filtro === "object" ? new URLSearchParams(filtro).toString() : "";
    return api
      .get(`/relatorio/financeiro/exportar?${filtros}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getEmbedTokenPowerBI(id_operadora) {
    return api
      .get(`/relatorio/bi/${id_operadora}`)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async addSuporte(data, id) {
    let metodo = "post";
    if (id) metodo = "put";
    return api[metodo](`/suporte`, data)
      .then((a) => a.data)
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 500) {
            return { errors: [error.response.data] };
          }
          return error.response.data;
        }
      });
  },

  async getTipoAnexo() {
    return [
      { id: 1, nome: "Exame" },
      { id: 2, nome: "Relatório" },
      { id: 3, nome: "Solicitação" },
      { id: 4, nome: "Cotação" },
      { id: 5, nome: "Outros" },
    ];
  },

  async getTipoAnexoCom() {
    return [
      { id: 6, nome: "Registro de comunicação" },
      { id: 7, nome: "Outros registros" },
      { id: 8, nome: "Acolhimento" },
      { id: 9, nome: "Abertura Beneficiário" },
      { id: 10, nome: "Abertura Profissional Assistente" },
      { id: 11, nome: "Escolha do Profissional Desempatador" },
      { id: 12, nome: "Abstenção de Escolha" },
      { id: 13, nome: "Encerrado prazo de escolha do Profissional Assistente" },
      { id: 14, nome: "Consenso" },
      { id: 15, nome: "Validação Consenso Parcial" },
      { id: 16, nome: "Telegrama - Enviado" },
      { id: 17, nome: "Telegrama - Recebido" },
      { id: 18, nome: "Telegrama Recusado" },
      { id: 19, nome: "Telegrama devolvido por excesso de tentativas" },
      { id: 20, nome: "Decisão Beneficiário" },
      { id: 21, nome: "Decisão Profissional Assistente" },
      { id: 22, nome: "Acolhimento abertura" },
      { id: 23, nome: "Acolhimento decisão" },
      { id: 24, nome: "Cancelamento" },
      { id: 25, nome: "Validação dos contatos do PA" },
    ];
  },

  getSexo() {
    return [
      { id: "M", nome: "Masculino" },
      { id: "F", nome: "Feminino" },
    ];
  },

  getEstados() {
    return [
      { id: "AC", nome: "Acre" },
      { id: "AL", nome: "Alagoas" },
      { id: "AM", nome: "Amazonas" },
      { id: "AP", nome: "Amapá" },
      { id: "BA", nome: "Bahia" },
      { id: "CE", nome: "Ceará" },
      { id: "DF", nome: "Distrito Federal" },
      { id: "ES", nome: "Espírito Santo" },
      { id: "GO", nome: "Goiás" },
      { id: "MA", nome: "Maranhão" },
      { id: "MG", nome: "Minas Gerais" },
      { id: "MS", nome: "Mato Grosso do Sul" },
      { id: "MT", nome: "Mato Grosso" },
      { id: "PA", nome: "Pará" },
      { id: "PB", nome: "Paraíba" },
      { id: "PR", nome: "Paraná" },
      { id: "PE", nome: "Pernambuco" },
      { id: "PI", nome: "Piauí" },
      { id: "RJ", nome: "Rio de Janeiro" },
      { id: "RN", nome: "Rio Grande do Norte" },
      { id: "RS", nome: "Rio Grande do Sul" },
      { id: "RO", nome: "Rondônia" },
      { id: "RR", nome: "Roraima" },
      { id: "SC", nome: "Santa Catarina" },
      { id: "SP", nome: "São Paulo" },
      { id: "SE", nome: "Sergipe" },
      { id: "TO", nome: "Tocantins" },
    ];
  },

  getEstadosUF() {
    return [
      { id: "AC", nome: "AC" },
      { id: "AL", nome: "AL" },
      { id: "AM", nome: "AM" },
      { id: "AP", nome: "AP" },
      { id: "BA", nome: "BA" },
      { id: "CE", nome: "CE" },
      { id: "DF", nome: "DF" },
      { id: "ES", nome: "ES" },
      { id: "GO", nome: "GO" },
      { id: "MA", nome: "MA" },
      { id: "MG", nome: "MG" },
      { id: "MS", nome: "MS" },
      { id: "MT", nome: "MT" },
      { id: "PA", nome: "PA" },
      { id: "PB", nome: "PB" },
      { id: "PR", nome: "PR" },
      { id: "PE", nome: "PE" },
      { id: "PI", nome: "PI" },
      { id: "RJ", nome: "RJ" },
      { id: "RN", nome: "RN" },
      { id: "RS", nome: "RS" },
      { id: "RO", nome: "RO" },
      { id: "RR", nome: "RR" },
      { id: "SC", nome: "SC" },
      { id: "SP", nome: "SP" },
      { id: "SE", nome: "SE" },
      { id: "TO", nome: "TO" },
    ];
  },

  getMotivosDeDivergenciaP() {
    return [
      { id: 12, active: true, nome: "Sem indicação técnica" },
      { id: 13, active: true, nome: "Quantidade" },
      { id: 14, active: true, nome: "Falta de cobertura no Rol" },
      { id: 16, active: true, nome: "Não está de acordo com a DUT da ANS" },
      { id: 18, active: true, nome: "Código sobreposto/excludente" },
      { id: 15, active: true, nome: "Outros" },
    ];
  },

  getMotivosDeDivergenciaPDivergencia() {
    return [
      { id: 11, active: true, nome: "Não há divergência" },
    ];
  },

  getMotivosDeDivergenciaM() {
    return [
      { id: 22, active: true, nome: "Sem indicação técnica" },
      { id: 23, active: true, nome: "Quantidade" },
      { id: 24, active: true, nome: "Off-label" },
      { id: 25, active: true, nome: "Apenas marcas" },
      { id: 26, active: true, nome: "Quantidade e marcas" },
      { id: 29, active: true, nome: "Substituição de material" },
      { id: 27, active: true, nome: "Outros" },
    ];
  },

  getMotivosDeDivergenciaMDivergencia() {
    return [
      { id: 21, active: true, nome: "Não há divergência" },
    ];
  },

  getSuporteTipos() {
    return [
      { id: 1, nome: "Dúvida de uso" },
      { id: 2, nome: "Relatar um problema" },
      { id: 3, nome: "Sugerir uma melhoria" },
      { id: 4, nome: "Outro" },
    ];
  },

  getSuporteModulos() {
    return [
      { id: 1, nome: "Login e conta do usuário" },
      { id: 2, nome: "Listagem de processos" },
      { id: 3, nome: "Gestão do processo" },
      { id: 4, nome: "Procedimentos/Materiais" },
      { id: 5, nome: "Material de apoio" },
      { id: 6, nome: "Relatório/BI" },
      { id: 7, nome: "Outros" },
    ];
  },
  getTipoAnalise() {
    return [
      { id: 0, nome: "Documental" },
      { id: 1, nome: "Videoconferência" },
    ];
  },
  getPlanoBeneficiario() {
    return [
      { id: 1, id_operadora: 14, nome: "+VIDA AME" },
      { id: 2, id_operadora: 14, nome: "E-VIDA ESSENCIAL" },
      { id: 3, id_operadora: 14, nome: "E-VIDA RORAIMA ENERGIA" },
      { id: 4, id_operadora: 14, nome: "E-VIDA FAMÍLIA" },
      { id: 5, id_operadora: 14, nome: "E-VIDA MELHOR IDADE" },
      { id: 6, id_operadora: 14, nome: "E-VIDA PPRS" },
      { id: 7, id_operadora: 14, nome: "E-VIDA PPRS GENITORES" },
      { id: 8, id_operadora: 14, nome: "E-VIDA RECIPROCIDADE" },
    ];
  }
};

export default business;
