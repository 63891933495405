import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import business from '../../services/business';
import Loading from '../Loading';
import Modal from '../Modal';
import InputForm from '../Form/InputForm';
import Search from '../Form/Search';
import { useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import tools from '../../lib/tools';
import { useAlert } from 'react-alert';

export default function ModalProcedimentos({ hideModal, show, model }) {

    const [state, setState] = useState({
        adicionando: false
    });

    const alert = useAlert();

    const [procedimentos, setProcedimentos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [loader, setLoader] = useState(false);
    const [adicionandoProcedimento, setAdicionandoProcedimento] = useState(false);
    const [form, setForm] = useState({});
    const [validacao, setValidacao] = useState([]);
    const [text, setText] = useState('');
    const [pager, setPager] = useState({
        page: 1,
        total_pages: 0,
        total_rows: 0,
    });

    const handlePageClick = (e) => {
        getSearch(e.selected + 1, text);
    };

    const handleWindowSizeChange = (mobileSize) => {
        let checkIsMobile = window.innerWidth < mobileSize;
        setIsMobile(checkIsMobile);
    }

    const getSearch = useCallback(async (page = null, value = '', loading = true) => {
        setLoading(loading);
        let usuarios = await business.getAllProcedimentos((page === null) ? pager.page : page, 10, value);
        setProcedimentos(usuarios?.response || [])
        if (usuarios?.response && pager.page && usuarios?.pager?.paginas > 0 && usuarios?.pager?.paginas < pager.page) {
            pager.page = 1;
            return getSearch();
        }
        pager.page = usuarios?.pager?.pagina;
        setPager({
            page: usuarios?.pager?.pagina,
            total_pages: usuarios?.pager?.paginas,
            total_rows: usuarios?.pager?.total
        });
        setLoading(false);
        setSearchLoading(false);
        setLoader(true);
        // eslint-disable-next-line 
    }, []);

    const add = async (e, item) => {
        e.preventDefault();
        if (validate(form)) {
            setAdicionandoProcedimento(true);
            item = { ...item, valor: (item?.valor) ? tools.prepareValue(item.valor) : 0.00 };
            let { errors, response } = await business.addProcedimento(item);
            if (errors && errors.length) {
                (errors).forEach(e => alert.error(e));
            } else {
                setState({ ...state, adicionando: false });
                if (response?.status) {
                    alert.success('O novo procedimento foi cadastrado.');
                    item.valor_unitario = item.valor;
                    item.id = response.id;
                    delete item.valor;
                    procedimentos.shift();
                    procedimentos.unshift(item);
                    setProcedimentos(procedimentos);
                    model({
                        id: item.id,
                        nome: item.desc,
                        codigo: item.codigo,
                        valor_unitario: item.valor_unitario,
                        cobertura_rol: 0
                    });
                    hideModal(e);
                }
                setForm({});
            }
            setAdicionandoProcedimento(false);
        }

    }

    const validate = useCallback((form) => {

        const obrigatorios = [];

        document.querySelectorAll('.modal-procedimentos [name][required]').forEach((e) => {
            if (e.name && e.style.display !== 'none') obrigatorios.push(e.name);
        });

        let validacao = {};
        let validated = true;

        obrigatorios.forEach(campo => {
            if ((!form && typeof form[campo] === 'undefined') || tools.isEmptyNullOrUndefined(form[campo])) {
                validacao[campo] = 'Campo Obrigatório';
                validated = false;
            }
        });

        setValidacao(validacao);

        return validated;
    }, []);

    const setModel = (name, value) => {
        setForm((prevState) => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        if (!loader) {
            getSearch();
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(() => {
        window.addEventListener('resize', () => {
            return handleWindowSizeChange(1180);
        });
    }, []);

    useEffect(() => {
        validate(form);
    }, [form, state, validate, procedimentos]);

    const renderForm = () => {
        return (loading) ? <Loading /> : (<>
            <div className="form-container grid col-12">
                <InputForm label="Código" type="text" mask={'###########'}
                    name="codigo" required className="span-2" maxLength={11} value={form?.codigo} model={setModel} error={validacao.codigo} />
                <InputForm label="Nome" type="text"
                    name="desc" required className="span-7" maxLength={64} value={form?.desc?.toUpperCase()} model={setModel} error={validacao.desc} />
                <InputForm label="Valor" type="decimal"
                    name="valor" className="span-3" value={form?.valor} model={setModel} error={validacao.valor} />
            </div>
            <div className="form-rodape mt-30">
                <button className="btn btn-outline-primary f700-16" onClick={() => setState({ ...state, adicionando: false })}>
                    Cancelar
                </button>
                <button className="btn btn-primary f700-16" onClick={(e) => add(e, form)} disabled={adicionandoProcedimento}  >
                    {adicionandoProcedimento ? <Loading /> : 'Salvar'}
                </button>
            </div>
        </>
        )
    }

    const renderGrid = () => {
        return (loading) ? <Loading /> : (
            <>
                <div className="flex">
                    <div className="col-4">
                        <Search placeholder="Digite o nome ou código.." defaultValue={text} onChange={(value) => {
                            setText(value);
                            setSearchLoading(true);
                            getSearch(1, value, false);
                        }} searchOnTyping={isMobile} loading={searchLoading} />
                    </div>
                    <div className="col-8">
                        <button className="btn btn-primary btn-md f700-16 float-right"
                            onClick={() => setState({ ...state, adicionando: true })}>Adicionar</button>
                    </div>
                </div>
                <div className="form-grid">
                    <div className="grid col-12 fb-16 p-10 mt-20">
                        <div className="span-2">Código</div>
                        <div className="span-8">Nome do procedimento</div>
                        <div className="span-2">Valor Unitário</div>
                    </div>
                    <div className="list-scroll">
                        {(procedimentos || []).map((procedimento, i) => {
                            return <div key={i}>
                                <div className="grid col-12 fn-16 list-scroll-item" title="Clique para selecionar" onClick={(e) => {
                                    e.preventDefault();
                                    model({
                                        id: procedimento.id,
                                        nome: procedimento.desc,
                                        codigo: procedimento.codigo,
                                        valor_unitario: procedimento.valor_unitario,
                                        cobertura_rol: procedimento?.cobertura_rol || 0
                                    });
                                    hideModal(e);
                                }}>
                                    <div className="span-2">{procedimento.codigo}</div>
                                    <div className="span-8">{procedimento.desc.toUpperCase()}</div>
                                    <div className="span-2">{tools.formatCurrency(procedimento.valor_unitario)}</div>
                                </div>
                            </div>
                        })}
                        {loader && !procedimentos?.length && (
                            <div>
                                <div className="grid col-12 convidados fn-16">
                                    <div className="span-12 text-center">Nenhum registro foi encontrado</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="component pagination">
                        <div className="pagination-container">
                            {pager.total_pages < 2 ? '' : <ReactPaginate
                                previousLabel="«"
                                nextLabel="»"
                                breakLabel="..."
                                breakClassName="break-me"
                                page={pager.page - 1 ?? 0}
                                forcePage={pager.page - 1 ?? 0}
                                pageCount={pager.total_pages}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName="pagination"
                                subContainerClassName="pages pagination"
                                activeClassName="active" />}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <Modal show={show} handleClose={hideModal} style={{ maxWidth: '1200px', maxHeight: '725px', height: 'inherit' }} modalClass="modal-cad-usuario modal-procedimentos">
            <div className="flex">
                <div className="form w-100 mb-30">
                    <h1 className="title mb-20">{state.adicionando ? 'Adicionar procedimento' : 'Procedimentos'}</h1>
                    {state.adicionando ? renderForm() : renderGrid()}
                </div>
            </div>
        </Modal>
    );
}
