import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import '../../../assets/styles/components/Layout.scss'
import business, { api } from "../../../services/business";
import Loading from "../../Loading";
import moment from 'moment-timezone';
import tools from "../../../lib/tools";
import config from "../../../config/config";
import ModalAlert from "../../Modais/ModalAlert";
import { useCallback } from "react";
import { useAlert } from "react-alert";
import InputForm from "../../Form/InputForm";
import Dropzone from "../../Form/Dropzone.js";

moment.tz.setDefault("America/Sao_Paulo");

export default function Comunicacoes({ form, carregarAnexos = true, setModel, options }) {

  const alert = useAlert();

  const [anexos, setAnexos] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);

  const [notificacao, setNotificacao] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});

  const canal = [
    {
      id: 1,
      nome: 'E-mail'
    },
    {
      id: 2,
      nome: 'Ligação'
    },
    {
      id: 3,
      nome: 'WhatsApp'
    },
    {
      id: 4,
      nome: 'Telegrama'
    }
  ]

  const getSearch = useCallback(async () => {
    setLoading(true);

    let response = await business.getNotificacaoDisparos({ processo: form.id });
    setNotificacao(response)

    setLoading(false);
  }, [form.id]);

  useEffect(() => {
    getSearch();
  }, [getSearch])

  const remover = async (e, item) => {
    e.preventDefault();

    return setModalData({
      text: 'Deseja realmente remover esta comunicação?',
      show: true,
      buttonCancel: true,
      onAccept: async (e) => {
        e.preventDefault();
        let res = await business.removeNotificacao(item.id, item.id_notificacao_canal)
        if (res?.errors?.length) {
          (res.errors).forEach(e => alert.error(e));
        } else {
          getSearch();
          alert.success('A comunicação foi removida com sucesso!');
        }
        setModalData({
          show: false
        });
      },
      onClose: (e) => {
        e.preventDefault();
        setModalData({
          show: false
        });
      }
    })
  }

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map((file) => {
      anexos.unshift({
        id: null,
        tipo: 6,
        desc_tipo: '',
        identificador: null,
        nome: file.name,
        descricao: '',
        extensao: file.type?.split('/')[1],
        adicionado: false,
        upload: true,
        deleted: false,
        data_cadastro: tools.getDatetime(),
        data_atualizacao: tools.getDatetime(),
        url: null,
        file: file,
        canal: 1,
        data_leitura: tools.getDatetime()
      });
      setAnexos(anexos)
      setModel('anexos', [...anexos]);
      return file;
    });
  }, [setModel, anexos]);

  const uploadFile = (i, anexo) => {
    const formData = new FormData();
    formData.append('file', anexo.file);
    formData.append('id_processo', form.id);
    formData.append('id_tipo', anexo.tipo);
    formData.append('descricao', anexo.descricao);
    formData.append('canal', anexo.canal);
    formData.append('data_leitura', anexo.data_leitura);
    setUploadStatus(true);
    anexos[i].upload = true;
    setAnexos([...anexos]);
    api.post('processo/' + form.id + '/anexo', formData, {
      headers: {
        'Authorization': `Bearer ${api.getToken()}`
      }
    }).then((response) => {
      if (response.data?.errors && response.data?.errors.length) {
        response.data?.errors.forEach(e => alert.error(e));
        setUploadStatus(false);
      } else {
        anexos[i].id = response.data?.response?.id;
        anexos[i].identificador = response.data?.response?.hash;
        anexos[i].url = response.data?.response?.url;
        anexos[i].adicionado = response.data?.response?.status;
        anexos[i].upload = false;
        delete anexos[i].file;
        form.anexos = anexos;
        setAnexos([...anexos]);
        setModel('anexos', [...anexos], true);
      }
      setUploadStatus(false);
    }).catch((error) => {
      alert.error(error?.message || 'Ocorreu um erro ao enviar o arquivo');
      setUploadStatus(false);
    });
  }

  const viewFile = async (url) => {
    fetch(url, {
      headers: new Headers({
        'Authorization': `Bearer ${api.getToken()}`
      })
    }).then((response) => response.blob())
      .then((blob) => {
        var _url = window.URL.createObjectURL(blob);
        window.open(_url, "_blank").focus();
      }).catch((err) => {
        // on error
      });
  }

  const adicionar = async (i, anexo) => {
    if (uploadStatus) {
      return false;
    }
    uploadFile(i, anexo);
  }

  const anexoRemover = async (e, i) => {
    e.preventDefault();
    if (deleteStatus) {
      return false;
    }
    if (!window.confirm('Deseja realmente deletar o arquivo ' + anexos[i].nome + '?')) {
      return false;
    }
    setDeleteStatus(true);
    anexos[i].deleted = true;
    if (anexos[i]?.id) {
      let data = await business.removerAnexo(form.id, anexos[i]);
      if (data?.response) {
        anexos.splice(i, 1);
        form.anexos = anexos;
        setAnexos(anexos);
        setModel('anexos', [...anexos], true);
      }
    } else {
      anexos.splice(i, 1);
      form.anexos = anexos;
      setAnexos(anexos);
      setModel('anexos', [...anexos], true);
    }
    setDeleteStatus(false);
  }

  useEffect(() => {
    async function fetchData() {
      if (!carregarAnexos) {
        return false;
      }
      if (!anexos || !anexos.length) {
        let response = await business.getAnexos(form.id, 2);
        if (response && response.length) {
          setAnexos(response);
          setModel('anexos', [...response]);
        }
      }
    }
    fetchData();
    // eslint-disable-next-line 
  }, [carregarAnexos]);

  return (
    <div className="junta-comunicacao mt-20 pt-0">
      <ModalAlert title={modalData?.title} text={modalData?.text} show={modalData?.show} onAccept={modalData?.onAccept} onClose={modalData?.onClose} buttonCancel={modalData?.buttonCancel} />
      <h1 className="f600-18 mb-30">Comunicações</h1>
      {loading ? (<Loading />) : (
        <>
          <div className="pb-20 flex md-gap">
            {form?.id_processo_tipo === '3' &&
              <button className="btn btn-primary f700-16 jc-sb" onClick={(e) => {
                e.preventDefault();
                tools.openUrlExternal(`/${form.id}/${form.id_processo_etapa_status}/dossie`)
              }} disabled={form?.id_processo_etapa !== '4'}>
                Dossiê completo <i className="icon icon-print rotate-180 i-20 ml-10" />
              </button>}
          </div>

          <div className="comunicacao-list overflow-y scrollfit h-100 flex fd-fc">
            {notificacao ? (notificacao || []).map((notificacao, i) => {
              return <div key={i}>
                <div className="flex ai-fs item gap-20">
                  <div className="circle" />
                  <div className="flex fd-fc fb-14 pb-25">
                    <div className="flex md-gap">
                      <p className="fn-16 mb-5 flex md-gap">{notificacao.desc_canal}: {notificacao.desc_conteudo}<span className={`badge-comunication badge-${notificacao.id_notificacao_envio_status}`}><strong>{notificacao.desc_status}</strong></span></p>
                    </div>
                    <p className="f400-12 mb-5"><strong>Data de envio:</strong> {moment(notificacao.data_cadastro, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs</p>
                    <p className="f400-12 mb-5"><strong>Data de leitura:</strong> {notificacao.data_leitura ? (moment(notificacao.data_leitura, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')) : ('-')}{notificacao.data_leitura ? ' hrs' : ''}</p>
                    <p className="f400-12 mb-5"><strong>Código de disparo:</strong> {notificacao.id}{form.id_protocolo}</p> {(config.user.tipo === '1' || config.user.tipo === '8') && <span className="f400-12 mb-5"><strong>Link:</strong> {notificacao.url}</span>}
                  </div>
                  {notificacao.id_notificacao_envio_status === '6' && <i className="icon icon-comments-question i-20 cursor-help" title={notificacao.resposta} />}
                  <a className="f-400-12 mb-5 link pointer" href={`/comunicacao/${notificacao.id}/${notificacao.id_processo}`} target="_blank" rel="noreferrer"><i className="icon icon-eye i-25 pointer" title="Visualizar conteúdo" /></a>
                  {([1, 5].includes(parseInt(config.user.tipo))) && <span onClick={(e) => remover(e, notificacao)}><i className="icon icon-trash watermelon i-25 pointer" title="Remover" /></span>}
                </div>
              </div>
            }) : <p>Não há comunicações no momento.</p>}
          </div>

          <h1 className="f600-18 mb-40 mt-30">Comunicações adicionais</h1>
          <div>
            {([1, 4, 5, 8].includes(parseInt(config.user.tipo))) && <Dropzone onDrop={onDrop} description={"Extensões: PDF, PNG, JPG, MP3 e OGG"} accept=".jpeg,.jpg,.png,.pdf,.mp3,.ogg" />}
            <div className="mt-60">
              {(anexos || []).map((anexo, i) => {
                return <div key={i} className="anexos flex ai-fc mb-30 gap-60">
                  <div className="form-container grid col-12">
                    <div className="span-5" onClick={() => {
                      viewFile(anexo.url);
                    }}>
                      <span className="pointer link"><i className="icon icon-add-document i-35 mb-10" /> {anexo.nome}</span>
                      <p className="fn-12 pt-5">Adicionado em: {moment(anexo.data_cadastro, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY - HH:mm')} hrs</p>
                    </div>
                    <div className="span-3">
                      <InputForm name="tipo" type="select" label="Tipo do documento" exibicao={anexo?.adicionado} value={anexo.tipo} options={options.tiposAnexoCom}
                        model={(name, value) => {
                          anexos[i][name] = parseInt(value);
                          setAnexos([...anexos]);
                        }} />
                    </div>
                    <div className="span-2">
                      <InputForm
                        label="Canal"
                        required
                        type="select"
                        className="gap-25"
                        name="canal"
                        options={canal}
                        exibicao={anexo?.adicionado}
                        value={anexo.canal || ""}
                        model={(name, id) => {
                          anexos[i][name] = parseInt(id);
                          setAnexos([...anexos]);
                        }}
                      />
                    </div>
                    <div className="span-2">
                      <InputForm
                        label="Data e Hora"
                        type="datetime-local"
                        exibicao={anexo?.adicionado}
                        value={anexo.data_leitura}
                        model={(name, value) => {
                          anexos[i].data_leitura = value;
                          setAnexos([...anexos]);
                        }}
                        name="data_leitura"
                      />
                    </div>
                    <div className="span-7">
                      {<InputForm label="Descrição" exibicao={anexo?.adicionado} type="text" value={anexo.descricao}
                        model={(name, value) => {
                          anexos[i].descricao = value;
                          setAnexos([...anexos]);
                        }}
                        name={'anexos.' + anexo.id + '.descricao'} />}
                    </div>
                    {<div className="span-4">
                      <span className="ml-25 mt-35 pointer float-right" onClick={(e) => anexoRemover(e, i)}>
                        {(anexo.deleted) ? <Loading /> : <i className="icon icon-trash watermelon i-25" />}
                      </span>
                      {(anexo.upload > 0) &&
                        <button className="btn btn-primary f700-16 mt-20 float-right" disabled={uploadStatus} onClick={() => adicionar(i, anexo)}>
                          {(anexo.upload && uploadStatus) ? (<Loading />) : 'Adicionar'}
                        </button>}
                    </div>}
                  </div>
                </div>
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}